import { captureException } from '@sentry/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useToast } from '@/uikit/components/Toast';

const useOnAsyncError = () => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return (error: unknown) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      showToast(t('error.sessionExpired'), {
        variant: 'warning',
        preventDuplicate: true,
      });

      return;
    }

    showToast(t('error.genericMessage'), {
      variant: 'error',
      preventDuplicate: true,
    });

    captureException(error);
  };
};

export default useOnAsyncError;
