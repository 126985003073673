import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  createFilterOptions,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddSupplier from '@/components/dialogs/AddSupplier';
import { endOfDay } from '@/lib/utils';
import { Supplier } from '@/models/Supplier';
import useFetchAllSuppliers from '@/queries/supplier/useFetchAllSuppliers';
import DatePicker from '@/uikit/components/DatePicker';
import Dialog from '@/uikit/components/Dialog';
import Button from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';

import styles from './NewInvoice.module.scss';

type NewInvoiceProps = {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (supplier: Supplier, date: Date) => void;
};

type SupplierOption = Supplier & { label: string };

const filter = createFilterOptions<SupplierOption>();

const parseSuppliers = (suppliers: Supplier[]): SupplierOption[] =>
  suppliers.map((supplier) => ({
    ...supplier,
    label: supplier.name,
  }));

const NewInvoice = ({
  isOpen,
  onClose,
  isLoading = false,
  onSubmit,
}: NewInvoiceProps) => {
  const { t } = useTranslation();
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [openNewSupplier, setOpenNewSupplier] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  const autoCompleteRef = useRef<HTMLInputElement>(null);

  const { data: suppliers = [], isLoading: isLoadingSuppliers } =
    useFetchAllSuppliers();

  useEffect(() => {
    if (!supplier) {
      setInputValue('');
    }
  }, [supplier]);

  const handleSupplierSelected = (value: string | SupplierOption | null) => {
    if (typeof value === 'string' || value === null) return;

    if (value.supplierID === -1) {
      setInputValue('');
      setSupplier(null);
      setNewSupplierName(value.name.toUpperCase());
      setOpenNewSupplier(true);

      return;
    }

    setSupplier(value);
  };

  const handleOnSubmit = () => {
    if (supplier) {
      onSubmit(supplier, invoiceDate || endOfDay(new Date()));
    }
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={onClose}>
        <DialogTitle>{t('newInvoiceDialog.title')}</DialogTitle>
        <DialogContent>
          <div className={styles.content}>
            <DatePicker
              label={t('newInvoiceDialog.date')}
              format={t('dates.dateFormat')}
              value={invoiceDate}
              onChange={setInvoiceDate}
            />

            <Autocomplete
              className={styles.name}
              size="small"
              autoHighlight
              freeSolo
              options={parseSuppliers(suppliers)}
              value={supplier as SupplierOption | null}
              inputValue={inputValue}
              getOptionLabel={(option) =>
                typeof option === 'string'
                  ? option
                  : option.label || option.name
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    supplierID: -1,
                    pricelistID: -1,
                    name: params.inputValue,
                    label: t('newInvoiceDialog.addPlaceholder', {
                      supplier: params.inputValue.toUpperCase(),
                    }),
                  });
                }

                return filtered;
              }}
              noOptionsText={t('newInvoiceDialog.noResult')}
              loading={isLoadingSuppliers}
              loadingText={t('newInvoiceDialog.loading')}
              onInputChange={(_event, value) => setInputValue(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  inputRef={autoCompleteRef}
                  label={t('newInvoiceDialog.searchSupplierLabel')}
                  placeholder={t('newInvoiceDialog.searchSupplierPlaceholder')}
                  variant="outlined"
                  margin="none"
                />
              )}
              onChange={(_event, value) =>
                handleSupplierSelected(value || null)
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('newInvoiceDialog.cancel')}
          </Button>
          <Button
            color="secondary"
            onClick={handleOnSubmit}
            loading={isLoading}
            disabled={!invoiceDate || !supplier}
          >
            {t('newInvoiceDialog.submit')}
          </Button>
        </DialogActions>
      </Dialog>

      <AddSupplier
        isOpen={openNewSupplier}
        name={newSupplierName}
        onSubmit={() => {
          setOpenNewSupplier(false);
        }}
        onClose={() => {
          setNewSupplierName('');
          setOpenNewSupplier(false);
        }}
      />
    </>
  );
};

export default NewInvoice;
