import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useTranslation } from 'react-i18next';

import Chip from '@/uikit/components/Chip';

const StatusChip = ({ isLoaded }: { isLoaded: boolean }) => {
  const { t } = useTranslation();

  return (
    <Chip
      icon={isLoaded ? <CheckCircleIcon /> : <PauseCircleIcon />}
      label={t('components.statusChip.itemState', {
        context: isLoaded ? 'sent' : 'draft',
      })}
      color={isLoaded ? 'success' : 'warning'}
      variant="outlined"
      size="small"
    />
  );
};

export default StatusChip;
