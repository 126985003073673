const colors = [
  '#013a63',
  '#01497c',
  '#014f86',
  '#2a6f97',
  '#2c7da0',
  '#468faf',
  '#61a5c2',
  '#89c2d9',
  '#a9d6e5',
  '#580C82',
];

export const getColor = (key: string) => {
  let sum = 0;
  for (let i = 0; i < key.length; i += 1) {
    sum += key.charCodeAt(i);
  }

  return colors[sum % colors.length];
};
