import { captureException } from '@sentry/react';
import cx from 'classnames';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import Button from '@/uikit/components/Button';

import styles from './ErrorFallback.module.scss';

type ErrorFallbackProps = FallbackProps & {
  className?: string;
};

const ErrorFallback = ({
  error,
  className,
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <div className={cx(styles.container, className)}>
      <span className={styles.message}>{t('error.genericMessage')}</span>
      <Button color="tertiary" onClick={() => resetErrorBoundary()}>
        {t('error.tryAgain')}
      </Button>
    </div>
  );
};

export default ErrorFallback;
