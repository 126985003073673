import { useMutation } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { getCategoryUsage } from '@/lib/api/category';

const useGetCategoryUsage = () => {
  const handleAsyncError = useOnAsyncError();

  return useMutation({
    mutationFn: ({
      categoryId,
      fromDate,
      toDate,
    }: {
      categoryId: number;
      fromDate: Date;
      toDate: Date;
    }) => {
      const from = fromDate.toISOString();
      const to = toDate.toISOString();

      return getCategoryUsage(categoryId, from, to);
    },
    onError: handleAsyncError,
  });
};

export default useGetCategoryUsage;
