import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from '@/components/ErrorFallback';
import LoadingState from '@/components/LoadingState';
import '@/config/axios';
import '@/config/i18n';
import '@/config/sentry';
import theme from '@/config/theme';
import { AuthProvider } from '@/context/auth';
import { QueryClientProvider } from '@/context/reactQuery';
import { ToastProvider } from '@/uikit/components/Toast';
import '@/uikit/styles/main.scss';

import AppRoutes from './routes';

const App = () => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <ToastProvider>
          <QueryClientProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Suspense fallback={<LoadingState />}>
                <ErrorBoundary
                  onReset={reset}
                  FallbackComponent={ErrorFallback}
                >
                  <AuthProvider>
                    <AppRoutes />
                  </AuthProvider>
                </ErrorBoundary>
              </Suspense>
            </LocalizationProvider>
            {import.meta.env.VITE_ENABLE_REACT_QUERY_DEV_TOOLS === 'true' && (
              <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition="bottom-left"
              />
            )}
          </QueryClientProvider>
        </ToastProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
