import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteInvoice } from '@/lib/api/invoice';

import { getInvoiceQueryKey } from './queryKeys';

const useDeleteInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (invoiceID: number) => deleteInvoice(invoiceID),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getInvoiceQueryKey(data) });
    },
  });
};

export default useDeleteInvoice;
