import MUITextField, {
  TextFieldProps as MUITextFieldProps,
} from '@mui/material/TextField';
import cx from 'classnames';

import styles from './TextField.module.scss';

export type TextFieldProps = MUITextFieldProps & {
  hasError?: boolean;
};

const TextField = ({ className, hasError, ...rest }: TextFieldProps) => {
  return (
    <MUITextField
      className={cx(styles.container, className)}
      error={!!hasError}
      {...rest}
    />
  );
};

export default TextField;
