import { LinearProgress } from '@mui/material';

import styles from './LoadingState.module.scss';

const LoadingState = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>deedit</h2>
      <div className={styles.progress}>
        <LinearProgress className={styles.linearProgress} />
      </div>
    </div>
  );
};

export default LoadingState;
