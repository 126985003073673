import { useQuery } from '@tanstack/react-query';

import { getUnloadProductsTrend } from '@/lib/api/analytics';
import { ProductAnalyticsTrend } from '@/models/Analytics';

import { getUnloadProductsTrendQueryKey } from './queryKeys';

const useGetUnloadProductsTrend = (from: string, to: string) => {
  return useQuery<ProductAnalyticsTrend[], Error>({
    queryKey: getUnloadProductsTrendQueryKey(from, to),
    queryFn: () => getUnloadProductsTrend(from, to),
  });
};

export default useGetUnloadProductsTrend;
