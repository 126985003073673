import { useQuery } from '@tanstack/react-query';

import { fetchAllInventories } from '@/lib/api/inventory';
import { InventoryInfo } from '@/models/Inventory';

import { fetchAllInventoriesQueryKey } from './queryKeys';

const useFetchAllInventories = () => {
  return useQuery<InventoryInfo[], Error>({
    queryKey: fetchAllInventoriesQueryKey(),
    queryFn: fetchAllInventories,
  });
};

export default useFetchAllInventories;
