import { NumericFormat } from 'react-number-format';

const CurrencyText = ({
  value,
}: {
  value: number | string | null | undefined;
}) => {
  if (!value && value !== 0) {
    return '--.--';
  }

  return (
    <NumericFormat
      prefix="€ "
      displayType="text"
      decimalSeparator="."
      decimalScale={4}
      thousandSeparator=" "
      value={value}
      allowLeadingZeros
    />
  );
};

export default CurrencyText;
