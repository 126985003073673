import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import StatusChip from '@/components/StatusChip';
import TableLoader from '@/components/TableLoader';
import useTablePagination from '@/hooks/useTablePagination';
import useFetchAllInventories from '@/queries/inventory/useFetchAllInventories';
import Button from '@/uikit/components/Button';
import IconButton from '@/uikit/components/IconButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';
import { formatDate } from '@/utils/formatDate';

import styles from './InventoryList.module.scss';

const InventoryList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: inventoryList = [], isLoading } = useFetchAllInventories();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.pageTitle}>
            <IconButton
              size="small"
              aria-label={t('commons.stock')}
              onClick={() => navigate('/')}
            >
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            {t('commons.inventoryList')}
          </h3>
          <div className={styles.headerActions}>
            <Button
              startIcon={<AddCircleIcon />}
              size="M"
              onClick={() => navigate('/inventory/new')}
            >
              {t('inventoryList.newInventory')}
            </Button>
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('inventoryList.inventoryListTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('inventoryList.date')}</TableHeadCell>
                    <TableHeadCell>
                      {t('inventoryList.lastUpdate')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('inventoryList.status')}
                    </TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? inventoryList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : inventoryList
                  ).map((inventory, idx) => (
                    <TableRow
                      key={inventory.countingID}
                      clickable
                      onClick={() => navigate(`${inventory.countingID}`)}
                    >
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ width: '35%' }}>
                        {formatDate(inventory.createdAt, 'EEEE - PP')}
                      </TableCell>
                      <TableCell style={{ width: '35%' }}>
                        {inventory.updatedAt
                          ? formatDate(inventory.updatedAt, 'EEEE - PP')
                          : ''}
                      </TableCell>
                      <TableCell
                        style={{ width: '20%', padding: '0 16px' }}
                        align="center"
                      >
                        <StatusChip isLoaded={inventory.isLoaded} />
                      </TableCell>
                      <TableCell
                        style={{ width: '10%', padding: '0 8px' }}
                        className={styles.tableCTA}
                        align="center"
                      >
                        <IconButton
                          color={inventory.isLoaded ? 'primary' : 'secondary'}
                        >
                          <ContentPasteSearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={5}
                      count={inventoryList?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>
      </>
    </Page>
  );
};

export default InventoryList;
