import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { saveUnload } from '@/lib/api/unload';
import { Unload } from '@/models/Unload';

import { getUnloadQueryKey } from './queryKeys';

const useSaveUnload = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (unload: Unload) => saveUnload(unload),
    onError: handleAsyncError,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: getUnloadQueryKey(data.unloadID),
      });
    },
  });
};

export default useSaveUnload;
