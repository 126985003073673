import MUIButton from '@mui/material/Button';
import cx from 'classnames';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import styles from './Button.module.scss';

export type ButtonProps = Omit<
  React.ComponentPropsWithRef<'button'>,
  'color'
> & {
  variant?: 'text' | 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'tertiary';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  renderSpan?: boolean;
  href?: string;
  size?: 'L' | 'M' | 'S';
  component?: React.ReactNode;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    variant = 'contained',
    color = 'primary',
    startIcon,
    endIcon,
    className,
    renderSpan = false,
    size = 'M',
    ...rest
  },
  ref,
) => (
  <MUIButton
    ref={ref}
    component={renderSpan ? 'span' : 'button'}
    variant={variant}
    startIcon={startIcon}
    endIcon={endIcon}
    classes={{ root: styles.container }}
    className={cx(
      {
        [styles.primary]: color === 'primary',
        [styles.secondary]: color === 'secondary',
        [styles.tertiary]: color === 'tertiary',
        [styles.large]: size === 'L',
        [styles.medium]: size === 'M',
        [styles.small]: size === 'S',
      },
      className,
    )}
    {...rest}
  />
);

export default forwardRef(Button);
