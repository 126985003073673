import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { deleteInventory } from '@/lib/api/inventory';

import { fetchAllInventoriesQueryKey, getInventoryQueryKey } from './queryKeys';

const useDeleteInventory = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (inventoryId: number) => deleteInventory(inventoryId),
    onError: handleAsyncError,
    onSuccess: (deletedId) => {
      queryClient.removeQueries({ queryKey: getInventoryQueryKey(deletedId) });
      queryClient.invalidateQueries({
        queryKey: fetchAllInventoriesQueryKey(),
      });
    },
  });
};

export default useDeleteInventory;
