import { useQuery } from '@tanstack/react-query';

import { getInventoryByID, getNewInventory } from '@/lib/api/inventory';
import { Inventory } from '@/models/Inventory';

import { getInventoryQueryKey } from './queryKeys';

const useGetInventory = (inventoryId: string) => {
  return useQuery<Inventory, Error>({
    queryKey: getInventoryQueryKey(inventoryId),
    queryFn: () => {
      if (inventoryId === 'new') {
        return getNewInventory();
      }

      return getInventoryByID(inventoryId);
    },
  });
};

export default useGetInventory;
