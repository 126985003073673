import { useEffect, useState } from 'react';

interface Product {
  productID: number;
  fullName: string;
}

const useSearchableProducts = <Type extends Product>(source: Type[]) => {
  const [productsStore, setProductsStore] = useState(source.slice());
  const [result, setResult] = useState(source.slice());
  const [searchQuery, setSearchQuery] = useState('');

  const updateProduct = (product: Type) => {
    const result = productsStore.map((source) => {
      return product.productID === source.productID ? product : source;
    });

    setProductsStore(result);
  };

  const addProduct = (newProduct: Type) => {
    let result: Type[] = [];

    if (productsStore.some((p) => p.productID === newProduct.productID)) {
      result = productsStore.map((product) => {
        if (product.productID !== newProduct.productID) return product;

        return newProduct;
      });
    } else {
      result = [newProduct, ...productsStore];
    }

    setProductsStore(result);
  };

  const deleteProduct = (productId: number) => {
    const result: Type[] = productsStore.filter(
      (product) => product.productID !== productId,
    );

    setProductsStore(result);
  };

  useEffect(() => {
    const trimmedValue = searchQuery.trim();

    if (!trimmedValue) {
      setResult(productsStore.slice());
    }

    setResult(() => {
      return productsStore.filter((product) =>
        product.fullName?.includes(trimmedValue.toUpperCase()),
      );
    });
  }, [searchQuery, productsStore]);

  return {
    allProducts: productsStore,
    setProductsStore,
    setSearchQuery,
    searchQuery,
    updateProduct,
    addProduct,
    deleteProduct,
    result,
  };
};

export default useSearchableProducts;
