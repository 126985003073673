import axios from 'axios';

import { AnalyticsTrend } from '@/models/Analytics';
import { Category } from '@/models/Category';

export const fetchAllCategories = async () => {
  const response = await axios.get<Category[]>('/categories');

  return response.data;
};

export const getCategoryEntries = async (
  categoryID: number,
  from: string,
  to: string,
) => {
  const response = await axios.get<AnalyticsTrend>(
    `/categories/entry/${categoryID}`,
    {
      params: { from, to },
    },
  );

  return response.data;
};

export const getCategoryUsage = async (
  categoryID: number,
  from: string,
  to: string,
) => {
  const response = await axios.get<AnalyticsTrend>(
    `/categories/usage/${categoryID}`,
    {
      params: { from, to },
    },
  );

  return response.data;
};
