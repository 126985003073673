import { useQuery } from '@tanstack/react-query';

import { fetchStock } from '@/lib/api/stock';
import { StockProduct } from '@/models/StockProduct';

import { getStockQueryKey } from './queryKeys';

const useFetchStock = () => {
  return useQuery<StockProduct[], Error>({
    queryKey: getStockQueryKey(),
    queryFn: fetchStock,
  });
};

export default useFetchStock;
