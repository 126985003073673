import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';
import cx from 'classnames';

import styles from './IconButton.module.scss';

const IconButton = ({ className, ...props }: IconButtonProps) => {
  return (
    <MUIIconButton className={cx(styles.container, className)} {...props} />
  );
};

export default IconButton;
