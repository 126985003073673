import { Auth } from '@aws-amplify/auth';
import axios, { AxiosRequestHeaders } from 'axios';
import { parseISO } from 'date-fns';

import { apiUrl } from './url';

axios.defaults.baseURL = apiUrl;

// Regex taken from complete precision version with timezone described here:
// https://stackoverflow.com/a/3143231
const isoStringDateFormat =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseDates = (data: any): unknown => {
  if (typeof data === 'string' && isoStringDateFormat.test(data)) {
    return parseISO(data);
  } else if (typeof data === 'object' && data !== null) {
    for (const key in data as object) {
      data[key] = parseDates(data[key]);
    }
  }

  return data;
};

axios.interceptors.response.use((response) => {
  response.data = parseDates(response.data);
  return response;
});

axios.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();

  const token = session.getIdToken().getJwtToken();

  config.headers = {
    ...config.headers,
    ...(token && { Authorization: token }),
  } as AxiosRequestHeaders;

  return config;
});
