import { useQuery } from '@tanstack/react-query';

import { getPricelistByID } from '@/lib/api/pricelist';
import { Pricelist } from '@/models/Pricelist';

import { getPricelistQueryKey } from './queryKeys';

const useGetPricelist = (pricelistId: number) => {
  return useQuery<Pricelist, Error>({
    queryKey: getPricelistQueryKey(pricelistId),
    queryFn: () => {
      return getPricelistByID(pricelistId);
    },
  });
};

export default useGetPricelist;
