import { Autocomplete, createFilterOptions } from '@mui/material';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '@/models/Product';
import useFindProduct from '@/queries/product/useFindProduct';
import TextField from '@/uikit/components/TextField';

import NewProduct from '../dialogs/NewProduct';

type SearchProductFieldProps = {
  className?: string;
  product: Product | null;
  disableCreate?: boolean;
  onChange: (product: Product | null) => void;
};

const filter = createFilterOptions<Product>();

const SearchProductField: ForwardRefRenderFunction<
  HTMLInputElement,
  SearchProductFieldProps
> = ({ className, product, disableCreate = false, onChange }, ref) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [newProductName, setNewProductName] = useState('');
  const { data: products = [], isLoading } = useFindProduct(searchQuery);

  const handleProductChange = (value: string | Product | null) => {
    if (typeof value === 'string' || value === null) {
      onChange(null);
      return;
    }

    if (value.productID === -1) {
      setNewProductName(value.name);
      setInputValue('');
      return;
    }

    onChange(value);
  };

  useEffect(() => {
    if (!product) {
      setInputValue('');
      setSearchQuery('');
    }
  }, [product]);

  return (
    <>
      <Autocomplete
        autoHighlight
        freeSolo={!disableCreate}
        inputValue={inputValue}
        value={product}
        className={className}
        options={products}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.fullName
        }
        noOptionsText={t('searchProductField.noResult')}
        loading={isLoading}
        loadingText={t('searchProductField.loading')}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (!disableCreate && params.inputValue !== '') {
            filtered.push({
              productID: -1,
              name: params.inputValue,
              fullName: t('searchProductField.addPlaceholder', {
                product: params.inputValue,
              }),
            } as unknown as Product);
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            label={t('searchProductField.searchProductLabel')}
            placeholder={t('searchProductField.searchProductPlaceholder')}
            variant="outlined"
            margin="none"
            onChange={(event) => setSearchQuery(event.target.value)}
            inputRef={ref}
          />
        )}
        onInputChange={(_event, value) => setInputValue(value)}
        onChange={(_event, value) => handleProductChange(value)}
      />
      {!disableCreate && (
        <NewProduct
          initialName={newProductName}
          isOpen={Boolean(newProductName)}
          onClose={() => setNewProductName('')}
        />
      )}
    </>
  );
};

export default forwardRef(SearchProductField);
