import i18next, { InitOptions, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const i18nConfig: InitOptions = {
  supportedLngs: ['en', 'it'],
  fallbackLng: 'it',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    loadPath: '/locales/{{lng}}/translation.json',
    requestOptions: {
      cache: 'no-store',
    },
  },
  parseMissingKeyHandler: (key) => {
    console.warn(`i18n: Missing translation for key: ${key}`);

    return key;
  },
};

use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18next;
