import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { saveSupplier } from '@/lib/api/supplier';
import { Supplier } from '@/models/Supplier';

import { fetchAllSuppliersQueryKey } from './queryKeys';

const useSaveSupplier = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (supplier: Omit<Supplier, 'supplierID' | 'pricelistID'>) =>
      saveSupplier(supplier),
    onError: handleAsyncError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: fetchAllSuppliersQueryKey() });
    },
  });
};

export default useSaveSupplier;
