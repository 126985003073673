import { NumericFormat, NumericFormatProps } from 'react-number-format';

import TextField, { TextFieldProps } from '@/uikit/components/TextField';

type CurrencyFieldProps = Omit<
  TextFieldProps,
  'value' | 'defaultValue' | 'type' | 'onChange'
> &
  Pick<NumericFormatProps, 'value' | 'defaultValue'> & {
    allowNegative?: boolean;
    onChange?: (value: number) => void;
  };

const QuantityField = ({
  onChange,
  allowNegative = false,
  ...rest
}: CurrencyFieldProps) => {
  return (
    <NumericFormat
      allowNegative={allowNegative}
      allowedDecimalSeparators={[]}
      decimalScale={0}
      thousandSeparator=" "
      placeholder="0"
      customInput={TextField}
      inputProps={{ style: { textAlign: 'center' } }}
      onValueChange={(values) => {
        onChange?.(values.floatValue ?? 0);
      }}
      onFocus={(event) => {
        event.target.select();
      }}
      {...rest}
    />
  );
};

export default QuantityField;
