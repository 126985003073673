import { useTranslation } from 'react-i18next';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import useGetUnloadProductsTrend from '@/queries/analytics/useGetUnloadProductsTrend';
import { getColor } from '@/utils/colors';

import Container from '../components/Container';
import { TotalsBarChartTooltip } from '../components/CustomTooltip';
import Loader from '../components/Loader';

import styles from './UnloadsProductsBarChart.module.scss';

const defaultFrom: string = new Date(new Date().getDate() - 7).toISOString();
const defaultTo: string = new Date().toISOString();

type UnloadsProductsBarChartProps = { from?: string; to?: string };

const UnloadsProductsBarChart = ({
  from = defaultFrom,
  to = defaultTo,
}: UnloadsProductsBarChartProps) => {
  const { t } = useTranslation();
  const { data: result } = useGetUnloadProductsTrend(from, to);

  if (!result) {
    return <Loader />;
  }

  const data = result.map((res) => ({
    ...res,
    fill: getColor(res.name),
    label: res.name,
  }));

  if (!data.length) {
    return (
      <Container className={styles.emptyState}>
        <span className={styles.message}>{t('commons.emptyResult')}</span>
      </Container>
    );
  }

  return (
    <Container className={styles.container}>
      <h5 className={styles.legend}>{t('widgets.totalByProduct')}</h5>
      <ResponsiveContainer
        width="100%"
        height={145}
        className={styles.responsiveContainer}
      >
        <BarChart data={data} maxBarSize={20}>
          <XAxis dataKey="name" />
          <Tooltip cursor={false} content={<TotalsBarChartTooltip />} />
          <Bar dataKey="total" />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default UnloadsProductsBarChart;
