import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import PricelistResults from '@/components/PricelistResults';
import TableLoader from '@/components/TableLoader';
import AddSupplier from '@/components/dialogs/AddSupplier';
import useTablePagination from '@/hooks/useTablePagination';
import useFindPricelistProduct from '@/queries/pricelist/useFindPricelistProduct';
import useFetchAllSuppliers from '@/queries/supplier/useFetchAllSuppliers';
import Button from '@/uikit/components/Button';
import IconButton from '@/uikit/components/IconButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';
import TextField from '@/uikit/components/TextField';

import styles from './SupplierList.module.scss';

const SupplierList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedValue, setDebouncedValue] = useDebounceValue('', 250);
  const { data: supplierList = [], isLoading } = useFetchAllSuppliers();
  const { data: pricelistProducts = [], isFetching } =
    useFindPricelistProduct(debouncedValue);
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.pageTitle}>
            <IconButton
              disabled
              size="small"
              aria-label={t('commons.supplierList')}
            >
              <LocalMallOutlinedIcon fontSize="inherit" />
            </IconButton>
            {t('commons.supplierList')}
          </h3>
          <div className={styles.headerActions}>
            <TextField
              className={styles.searchTextField}
              placeholder={t('commons.searchProduct')}
              type="search"
              value={searchQuery}
              size="small"
              onChange={(event) => {
                setSearchQuery(event.target.value);
                setDebouncedValue(event.target.value);
              }}
            />
            <Button
              startIcon={<AddCircleIcon />}
              size="M"
              onClick={() => setIsDialogOpen(true)}
            >
              {t('supplierList.newSupplier')}
            </Button>
          </div>
        </Header>
        <Body>
          {pricelistProducts.length > 0 || isFetching ? (
            <PricelistResults products={pricelistProducts} />
          ) : isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('supplierList.supplierListTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('supplierList.name')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('supplierList.contact')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('supplierList.phone')}
                    </TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? supplierList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : supplierList
                  ).map((supplier, idx) => (
                    <TableRow
                      key={supplier.supplierID}
                      clickable
                      onClick={() =>
                        navigate(
                          `${supplier.supplierID}/pricelist/${supplier.pricelistID}`,
                        )
                      }
                    >
                      <TableCell style={{ width: 25 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        style={{ width: '35%' }}
                        className={styles.supplierName}
                      >
                        {supplier.name}
                      </TableCell>
                      <TableCell style={{ width: '30%' }} align="center">
                        {supplier.contact}
                      </TableCell>
                      <TableCell
                        style={{ width: '20%', padding: '0 16px' }}
                        align="center"
                      >
                        {supplier.contactPhone}
                      </TableCell>
                      <TableCell
                        style={{ width: 50, padding: '0 16px' }}
                        className={styles.tableCTA}
                        align="center"
                      >
                        <IconButton color="primary">
                          <ContentPasteSearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={5}
                      count={supplierList?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
          <AddSupplier
            isOpen={isDialogOpen}
            onSubmit={() => {
              setIsDialogOpen(false);
            }}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          />
        </Body>
      </>
    </Page>
  );
};

export default SupplierList;
