import { createTheme } from '@mui/material/styles';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const LinkBehavior: ForwardRefRenderFunction<
  never,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
> = (props, ref) => {
  const { href, ...rest } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...rest}></RouterLink>;
};

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: forwardRef(LinkBehavior),
      },
    },
  },
});

export default theme;
