import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/context/auth';
import { setNewPassword } from '@/lib/api/auth';
import { AuthTokens } from '@/models/Auth';
import { User } from '@/models/User';

const useSetNewPassword = (
  onCredentialError: (error: unknown) => void = () => console.log('Error'),
) => {
  const { handleLogin } = useAuth();

  return useMutation({
    mutationFn: setNewPassword,
    onSuccess: (payload) => {
      console.log(payload);

      if (payload) {
        handleLogin(payload as { user: User; tokens: AuthTokens });
      }
    },
    onError: (error) => {
      onCredentialError(error);
      return;
    },
    throwOnError: false,
  });
};

export default useSetNewPassword;
