import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CurrencyField from '@/components/CurrencyField';
import QuantityField from '@/components/QuantityField';
import SearchProductField from '@/components/SearchProductField';
import { formatPrice } from '@/lib/utils';
import { InvoiceSaveProduct } from '@/models/Invoice';
import { Product } from '@/models/Product';
import useGetSupplierProduct from '@/queries/supplier/useGetSupplierProduct';
import Button from '@/uikit/components/Button';
import Dialog from '@/uikit/components/Dialog';
import Switch from '@/uikit/components/Switch';

import styles from './AddInvoiceProduct.module.scss';

export type DialogData = {
  product: Product;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  updatePricelist?: boolean;
};

type AddInvoiceProductProps = {
  supplierId: number;
  isOpen: boolean;
  data?: DialogData;
  onClose: () => void;
  onSubmit: (product: InvoiceSaveProduct) => void;
};

export const AddInvoiceProduct = ({
  supplierId,
  isOpen,
  onClose,
  onSubmit,
  data,
}: AddInvoiceProductProps) => {
  const { t } = useTranslation();
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [product, setProduct] = useState<Product | null>(data?.product || null);
  const [quantity, setQuantity] = useState(data?.quantity || 1);
  const [unitPrice, setUnitPrice] = useState(data?.unitPrice || 0);
  const [totalPrice, setTotalPrice] = useState(data?.totalPrice || 0);
  const [updatePricelist, setUpdatePricelist] = useState(
    data?.updatePricelist ?? true,
  );
  const { data: details } = useGetSupplierProduct(
    supplierId,
    product?.productID || 0,
  );

  useEffect(() => {
    if (data) {
      setProduct(data.product);
      setQuantity(data.quantity);
      setUnitPrice(data.unitPrice);
      setTotalPrice(data.totalPrice);
      setUpdatePricelist(data.updatePricelist ?? true);
    }
  }, [data]);

  useEffect(() => {
    if (data) return;

    setUnitPrice(details?.privatePrice || 0);
    setTotalPrice(details?.privatePrice || 0);
  }, [data, details]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchFieldRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const resetValues = () => {
    setProduct(null);
    setQuantity(1);
    setUnitPrice(0);
    setTotalPrice(0);
    setUpdatePricelist(true);
  };

  const handleAddProduct = () => {
    if (product) {
      onSubmit({
        ...product,
        quantity,
        unitPrice,
        totalPrice,
        updatePricelist,
      });

      resetValues();
      searchFieldRef.current?.focus();
    }
  };

  const handleClose = () => {
    resetValues();
    onClose();
  };

  const handleUnitPriceChange = (value: number) => {
    setUnitPrice(value);
    setTotalPrice(formatPrice(value * quantity));
  };

  const handleTotalPriceChange = (value: number) => {
    setTotalPrice(value);
    setUnitPrice(formatPrice(value / quantity));
  };

  const handleQuantityChange = (value: number) => {
    setQuantity(value);
    if (unitPrice) {
      setTotalPrice(formatPrice(value * unitPrice));
    } else if (totalPrice) {
      setUnitPrice(formatPrice(totalPrice / value));
    }
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{t('invoiceDialog.title')}</DialogTitle>
      <DialogContent className={styles.content}>
        <SearchProductField
          ref={searchFieldRef}
          className={styles.search}
          product={product}
          onChange={(item: Product | null) => {
            setProduct(item);
            setQuantity(1);
          }}
        />
        <QuantityField
          fullWidth
          className={styles.quantity}
          label={t('invoiceDialog.quantity')}
          value={quantity}
          placeholder="1"
          onChange={handleQuantityChange}
        />
        <CurrencyField
          fullWidth
          className={styles.unitPrice}
          label={t('invoiceDialog.pricePerUnit')}
          value={unitPrice}
          onChange={handleUnitPriceChange}
        />
        <CurrencyField
          fullWidth
          className={styles.totalPrice}
          label={t('invoiceDialog.totalPrice')}
          value={totalPrice}
          onChange={handleTotalPriceChange}
        />
        <>
          <p className={styles.subtitle}>{t('invoiceDialog.details')}</p>
          <CurrencyField
            disabled
            fullWidth
            className={styles.unitPrice}
            label={t('invoiceDialog.pricelist')}
            value={details?.publicPrice || 0}
            placeholder="--.--"
          />
          <CurrencyField
            disabled
            fullWidth
            className={styles.quantity}
            label={t('invoiceDialog.canvas')}
            value={details?.privatePrice || 0}
            placeholder="--.--"
          />
          <CurrencyField
            disabled
            fullWidth
            className={styles.totalPrice}
            label={t('invoiceDialog.lastInvoice')}
            placeholder="--.--"
          />
        </>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <FormControlLabel
          className={styles.switchLabel}
          control={
            <Switch
              size="small"
              color="primary"
              checked={updatePricelist}
              onChange={(event) => {
                setUpdatePricelist(event.target.checked);
              }}
            />
          }
          label={t('invoiceDialog.updatePricelist')}
          labelPlacement="end"
        />
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t('invoiceDialog.cancel')}
        </Button>
        <Button
          color="secondary"
          disabled={!product}
          onClick={handleAddProduct}
        >
          {t('invoiceDialog.submit', {
            context: data ? 'update' : '',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
