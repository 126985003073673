import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Supplier } from '@/models/Supplier';
import useSaveSupplier from '@/queries/supplier/useSaveSupplier';
import Button from '@/uikit/components/Button';
import Dialog from '@/uikit/components/Dialog';
import LoadingButton from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';

import styles from './AddSupplier.module.scss';

type AddSupplierProps = {
  isOpen: boolean;
  name?: string;
  onClose: () => void;
  onSubmit: (data: Supplier) => void;
};

const AddSupplier = ({
  isOpen,
  name: initialName,
  onClose,
  onSubmit,
}: AddSupplierProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState(initialName);
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const { mutate: saveSupplier, isPending: isSaving } = useSaveSupplier();

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const handleAddSupplier = () => {
    if (!name) {
      return;
    }

    saveSupplier(
      {
        name,
        contact,
        contactPhone: phone,
        contactEmail: email,
      },
      { onSuccess: (supplier) => onSubmit(supplier) },
    );
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogTitle>{t('addSupplier.title')}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <TextField
            fullWidth
            required
            label={t('addSupplier.name')}
            value={name}
            placeholder={t('addSupplier.namePlaceholder')}
            onChange={(event) => setName(event.target.value || '')}
          />
          <TextField
            fullWidth
            label={t('addSupplier.contact')}
            value={contact}
            placeholder={t('addSupplier.contactPlaceholder')}
            onChange={(event) => setContact(event.target.value || '')}
          />
          <TextField
            fullWidth
            label={t('addSupplier.phone')}
            value={phone}
            placeholder={t('addSupplier.phonePlaceholder')}
            onChange={(event) => setPhone(event.target.value || '')}
          />
          <TextField
            fullWidth
            label={t('addSupplier.email')}
            value={email}
            placeholder={t('addSupplier.emailPlaceholder')}
            onChange={(event) => setEmail(event.target.value || '')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isSaving}
          onClick={onClose}
        >
          {t('addSupplier.cancel')}
        </Button>
        <LoadingButton
          color="secondary"
          loading={isSaving}
          onClick={handleAddSupplier}
        >
          {t('addSupplier.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddSupplier;
