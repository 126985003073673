import { useTranslation } from 'react-i18next';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import useGetUnloadCategoriesTrend from '@/queries/analytics/useGetUnloadCategoriesTrend';
import { getColor } from '@/utils/colors';

import Container from '../components/Container';
import { PercentagePieChartTooltip } from '../components/CustomTooltip';
import Loader from '../components/Loader';

import styles from './UnloadCategoriesPieChart.module.scss';

type UnloadCategoriesPieChartProps = { from?: string; to?: string };

const defaultFrom: string = new Date(new Date().getDate() - 7).toISOString();
const defaultTo: string = new Date().toISOString();

const UnloadCategoriesPieChart = ({
  from = defaultFrom,
  to = defaultTo,
}: UnloadCategoriesPieChartProps) => {
  const { t } = useTranslation();
  const { data: result } = useGetUnloadCategoriesTrend(from, to);

  if (!result) {
    return <Loader />;
  }

  const data = result.map((res) => ({
    ...res,
    fill: getColor(res.name || 'Other'),
    label: res.name || t('widgets.totalByCategory'),
  }));

  const total = Number(
    data.reduce((sum, product) => sum + product.total, 0).toFixed(2),
  );

  return (
    <Container className={styles.container}>
      <div className={styles.total}>
        <p>{total}</p>
        <p className={styles.subtitle}>{t('widgets.totalCategories')}</p>
      </div>
      <ResponsiveContainer width="100%" height={150}>
        <PieChart width={400} height={400}>
          <Pie
            cornerRadius={10}
            cy={70}
            data={data}
            dataKey="percentage"
            innerRadius={55}
            outerRadius={65}
            paddingAngle={1}
          />
          <Tooltip content={<PercentagePieChartTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default UnloadCategoriesPieChart;
