import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';

import styles from './Dropdown.module.scss';

type DropdownProps = {
  id: string;
  label: string;
  values: { label: string; value: string }[];
  defaultValue: string;
  onChange: (value: string) => void;
};

const Dropdown = ({
  id,
  label,
  values,
  defaultValue,
  onChange,
}: DropdownProps) => {
  const [selected, setSelected] = useState(
    () => defaultValue || values[0].value,
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelected(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={styles.container}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        className={styles.select}
        label={label}
        labelId={id}
        margin="dense"
        defaultValue={defaultValue}
        value={selected}
        MenuProps={{
          classes: {
            paper: styles.paper,
          },
        }}
        onChange={handleChange}
        inputProps={{
          name: 'type',
          id: `select-option-${id}`,
        }}
      >
        {values.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
