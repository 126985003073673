import { useQuery } from '@tanstack/react-query';

import { findProductByName } from '@/lib/api/product';
import { Product } from '@/models/Product';

import { findProductByNameQueryKey } from './queryKeys';

const useFindProduct = (name: string) => {
  return useQuery<Product[], Error>({
    queryKey: findProductByNameQueryKey(name),
    queryFn: () => findProductByName(name),
  });
};

export default useFindProduct;
