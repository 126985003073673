import { useQuery } from '@tanstack/react-query';

import { fetchAllCategories } from '@/lib/api/category';
import { Category } from '@/models/Category';

import { fetchAllCategoriesQueryKey } from './queryKeys';

const useFetchAllCategories = () => {
  return useQuery<Category[], Error>({
    queryKey: fetchAllCategoriesQueryKey(),
    queryFn: fetchAllCategories,
  });
};

export default useFetchAllCategories;
