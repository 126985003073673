import { useQuery } from '@tanstack/react-query';

import { getInvoiceByID } from '@/lib/api/invoice';
import { Invoice } from '@/models/Invoice';

import { getInvoiceQueryKey } from './queryKeys';

const useGetInvoice = (
  supplierId: string,
  invoiceId: string,
  date?: string,
) => {
  return useQuery<Invoice, Error>({
    queryKey: getInvoiceQueryKey(invoiceId),
    queryFn: () => {
      return getInvoiceByID(supplierId, invoiceId, date);
    },
  });
};

export default useGetInvoice;
