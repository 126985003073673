import { Link } from '@mui/material';
import { captureException } from '@sentry/react';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/context/auth';
import useSetNewPassword from '@/queries/auth/useSetNewPassword';
import Button from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';
import { useToast } from '@/uikit/components/Toast';

import styles from './UpdatePassword.module.scss';

const UpdatePassword = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const onCredentialError = () => {
    showToast(t('error.invalidPassword'), {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const { mutate: setNewPasswordCallback, isPending } =
    useSetNewPassword(onCredentialError);
  const navigate = useNavigate();
  const { cognitoUser } = useAuth();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (newPassword && newPassword !== newPasswordConfirmation) return;

    if (!cognitoUser) {
      captureException(new Error('Cognito user is not defined'));
      navigate('/', { replace: true });

      return;
    }

    setNewPasswordCallback(
      { password: newPassword, cognitoUser },
      {
        onSuccess: () => {
          navigate('/', { replace: true });
        },
      },
    );
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.title}>{t('auth.updateYourPassword')}</h1>
        <TextField
          required
          placeholder={t('auth.newPassword')}
          value={newPassword}
          type="password"
          onChange={(event) => setNewPassword(event.target.value)}
        />

        <TextField
          required
          placeholder={t('auth.newPasswordConfirmation')}
          value={newPasswordConfirmation}
          type="password"
          onChange={(event) => setNewPasswordConfirmation(event.target.value)}
        />
        <h2 className={styles.passwordRequirement}>
          {t('auth.passwordRequirement')}
        </h2>

        <Button
          size="L"
          className={styles.submit}
          type="submit"
          value="Submit"
          loading={isPending}
        >
          {t('auth.submit')}
        </Button>
        <Link href="/login" className={styles.loginLink}>
          {'Back to Login'}
        </Link>
      </form>
    </div>
  );
};

export default UpdatePassword;
