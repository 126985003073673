/* eslint-disable import/no-duplicates */
import { format as dateFnsFormat } from 'date-fns';
import { enUS as en, it } from 'date-fns/locale';
import i18next from 'i18next';

export type Language = 'en' | 'it';

let language: Language = i18next.language === 'it' ? 'it' : 'en';

const locales = {
  en,
  it,
};

const setLanguage = (lg: Language): void => {
  language = lg;
};

export const formatDate = (date: string | number | Date, formatStr: string) => {
  if (typeof date === 'string') {
    return dateFnsFormat(new Date(date), formatStr, {
      locale: locales[language],
    });
  }

  return dateFnsFormat(date, formatStr, {
    locale: locales[language],
  });
};

i18next.on('languageChanged', (lng) => {
  setLanguage(lng === 'it' ? 'it' : 'en');
});
