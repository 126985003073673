import MUIChip, { ChipProps } from '@mui/material/Chip';
import cx from 'classnames';

import styles from './Chip.module.scss';

const Chip = ({ className, ...props }: ChipProps) => {
  return <MUIChip className={cx(styles.container, className)} {...props} />;
};

export default Chip;
