export enum UnitOfMeasure {
  CL = 'cl',
  ML = 'ml',
  LT = 'lt',
  UNIT = 'unit',
  GR = 'gr',
  KG = 'kg',
}

export type Product = {
  brand?: string;
  categoryID?: number;
  categoryName?: string;
  cl?: number;
  fullName: string;
  ml: number;
  name: string;
  nameExtended: string;
  nameID: string;
  origin?: string;
  productID: number;
  size: number;
  um: UnitOfMeasure;
};

export type NewProduct = Pick<Product, 'name' | 'size' | 'um' | 'categoryID'>;
