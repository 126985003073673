import styles from './CustomTooltip.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TotalsBarChartTooltip = ({ active, payload }: any) => {
  if (active && payload?.length) {
    const {
      value,
      payload: { name },
    } = payload[0];

    return (
      <div className={styles.container}>
        <p className={styles.label}>{name}</p>
        <p className={styles.value}>{value}</p>
      </div>
    );
  }

  return null;
};

export default TotalsBarChartTooltip;
