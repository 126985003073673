import cx from 'classnames';

import styles from './Container.module.scss';

const Container = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

export default Container;
