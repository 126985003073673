import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CurrencyField from '@/components/CurrencyField';
import CurrencyText from '@/components/CurrencyText';
import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import TableLoader from '@/components/TableLoader';
import {
  AddInvoiceProduct,
  DialogData,
} from '@/components/dialogs/AddIInvoiceProduct';
import useSearchableProducts from '@/hooks/useSearchableProducts';
import useTablePagination from '@/hooks/useTablePagination';
import { formatPrice } from '@/lib/utils';
import { InvoiceSaveProduct } from '@/models/Invoice';
import useDeleteInvoice from '@/queries/invoice/useDeleteInvoice';
import useGetInvoice from '@/queries/invoice/useGetInvoice';
import useSaveInvoice from '@/queries/invoice/useSaveInvoice';
import useGetSupplier from '@/queries/supplier/useGetSupplier';
import DatePicker from '@/uikit/components/DatePicker';
import IconButton from '@/uikit/components/IconButton';
import Button from '@/uikit/components/LoadingButton/Button';
import Menu from '@/uikit/components/Menu';
import MenuItem from '@/uikit/components/MenuItem';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';

import styles from './InvoicePage.module.scss';

const InvoicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { invoiceID, supplierID } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { data: invoice, isLoading } = useGetInvoice(
    supplierID || '',
    invoiceID || '',
    queryParams.get('date') || undefined,
  );
  const { data: supplier } = useGetSupplier(Number(supplierID));
  const { mutate: saveInvoice, isPending: isSaving } = useSaveInvoice();
  const { mutate: deleteInvoice, isPending: isDeleting } = useDeleteInvoice();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const [date, setDate] = useState<Date | null>(null);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<DialogData>();
  const { addProduct, deleteProduct, setProductsStore, allProducts } =
    useSearchableProducts<InvoiceSaveProduct>(invoice?.products || []);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleSaveInvoice = () => {
    if (!supplier) return;

    saveInvoice(
      {
        invoiceID: invoice?.invoiceID,
        createdAt: date?.toISOString() || '',
        isLoaded: true,
        products: allProducts,
        pricelistID: supplier.pricelistID,
        supplierID: supplier.supplierID || 0,
        supplierName: supplier.name,
        total: totalInvoice,
      },
      {
        onSuccess: () => {
          navigate(-1);
        },
      },
    );
  };

  const handleDeleteInvoice = () => {
    if (invoice?.invoiceID) {
      deleteInvoice(invoice.invoiceID, {
        onSuccess: () => {
          navigate(-1);
        },
      });

      handleCloseMenu();
    }
  };

  useEffect(() => {
    if (invoice) {
      setDate(new Date(invoice.createdAt));
      setProductsStore(invoice.products || []);
      setIsDialogOpen(invoice.products?.length === 0);
    }
  }, [invoice, setProductsStore]);

  useEffect(() => {
    setTotalInvoice(
      formatPrice(
        allProducts.reduce((acc, current) => acc + current.totalPrice, 0),
      ),
    );
  }, [allProducts]);

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.title}>
            <IconButton
              size="small"
              aria-label={t('commons.invoice')}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            <div className={styles.name}>{supplier?.name || ''}</div>
          </h3>
          <div className={styles.headerActions}>
            {isLoading ? (
              <>
                <Skeleton
                  width={180}
                  style={{ lineHeight: '66px', borderRadius: '8px' }}
                />
                <Skeleton
                  width={166}
                  style={{ lineHeight: '66px', borderRadius: '8px' }}
                />
                <div className={styles.spacer} />
                <Skeleton
                  width={120}
                  style={{ lineHeight: '66px', borderRadius: '10px' }}
                />
                <Skeleton
                  width={120}
                  style={{ lineHeight: '66px', borderRadius: '10px' }}
                />
                <Skeleton
                  width={10}
                  style={{
                    lineHeight: '60px',
                    borderRadius: '10px',
                    marginRight: 16,
                  }}
                />
              </>
            ) : (
              <>
                <DatePicker
                  format={t('dates.dateFormat')}
                  value={date}
                  onChange={(value) => setDate(value)}
                />
                <CurrencyField
                  label={t('invoice.invoiceTotal')}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                  placeholder="0"
                  size="small"
                  value={totalInvoice}
                />
                <div className={styles.spacer} />
                <div className={styles.buttons}>
                  <Button
                    onClick={() => {
                      setDialogData(undefined);
                      setIsDialogOpen(true);
                    }}
                    className={styles.addProductButton}
                    loading={isSaving || isDeleting}
                    startIcon={<PlaylistAddIcon />}
                  >
                    {t('invoice.add')}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={handleSaveInvoice}
                    loading={isSaving || isDeleting}
                    startIcon={<DoneIcon />}
                  >
                    {t('commons.save')}
                  </Button>
                  <>
                    <IconButton
                      id="more-menu"
                      aria-controls={openMenu ? 'more-invoice-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onClick={(event) => {
                        setMenuAnchorEl(event.currentTarget);
                      }}
                      color="primary"
                      disabled={isDeleting}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="more-invoice-menu"
                      anchorEl={menuAnchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        'aria-labelledby': 'more-menu',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <MenuItem onClick={handleDeleteInvoice}>
                        <ListItemIcon>
                          <DeleteForeverIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('commons.delete')}</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                </div>
              </>
            )}
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('invoice.invoiceTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('invoice.product')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoice.category')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoice.quantity')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoice.unitPrice')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoice.totalPrice')}
                    </TableHeadCell>
                    <TableHeadCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? allProducts.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : allProducts
                  ).map((product, idx) => (
                    <TableRow
                      key={product.productID}
                      clickable
                      onClick={() => {
                        setDialogData({
                          product,
                          quantity: product.quantity,
                          unitPrice: product.unitPrice,
                          totalPrice: product.totalPrice,
                          updatePricelist: product.updatePricelist,
                        });
                        setIsDialogOpen(true);
                      }}
                    >
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ width: '35%' }}>
                        {product.fullName}
                      </TableCell>
                      <TableCell style={{ width: '15%' }} align="center">
                        {product.categoryName}
                      </TableCell>
                      <TableCell
                        style={{ width: '15%', padding: '0 16px' }}
                        align="center"
                      >
                        {product.quantity}
                      </TableCell>
                      <TableCell style={{ width: '15%' }} align="center">
                        <CurrencyText value={product.unitPrice} />
                      </TableCell>
                      <TableCell style={{ width: '15%' }} align="center">
                        <CurrencyText value={product.totalPrice} />
                      </TableCell>
                      <TableCell
                        style={{ width: 100, padding: '0 10px 0 0' }}
                        align="center"
                      >
                        <IconButton
                          size="small"
                          aria-label={t('commons.stock')}
                          color="error"
                          className={styles.deleteItemIcon}
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteProduct(product.productID);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={7}
                      count={allProducts.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>
        <AddInvoiceProduct
          supplierId={Number(supplierID)}
          isOpen={isDialogOpen}
          data={dialogData}
          onSubmit={addProduct}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    </Page>
  );
};

export default InvoicePage;
