import { useQuery } from '@tanstack/react-query';

import { fetchAllUnloads } from '@/lib/api/unload';
import { UnloadInfo } from '@/models/Unload';

import { fetchAllUnloadsQueryKey } from './queryKeys';

const useFetchAllUnloads = () => {
  return useQuery<UnloadInfo[], Error>({
    queryKey: fetchAllUnloadsQueryKey(),
    queryFn: fetchAllUnloads,
  });
};

export default useFetchAllUnloads;
