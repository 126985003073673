export const formatSearchName = (name: string): string =>
  name.trim().replace(/\s+/g, ' ').toLowerCase();

export const formatPrice = (value: number | string) =>
  Number(Number(value).toFixed(4));

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format(
    value,
  );
