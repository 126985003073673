import { useQuery } from '@tanstack/react-query';

import { getCurrentUser } from '@/lib/api/auth';
import { User } from '@/models/User';

import { ReactQueryOptions } from '../types';

import { getCognitoUserQueryKey } from './queryKeys';

const useGetCurrentUser = (options?: ReactQueryOptions<User | null>) => {
  return useQuery({
    queryKey: getCognitoUserQueryKey(),
    queryFn: getCurrentUser,
    throwOnError: false,
    gcTime: 0,
    retry: 0,
    ...options,
  });
};

export default useGetCurrentUser;
