import { useQuery } from '@tanstack/react-query';

import { fetchAllInvoices } from '@/lib/api/invoice';
import { InvoiceInfo } from '@/models/Invoice';

import { fetchAllInvoicesQueryKey } from './queryKeys';

const useFetchAllInvoices = () => {
  return useQuery<InvoiceInfo[], Error>({
    queryKey: fetchAllInvoicesQueryKey(),
    queryFn: fetchAllInvoices,
  });
};

export default useFetchAllInvoices;
