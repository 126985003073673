import axios from 'axios';

import { SupplierUsage } from '@/models/Analytics';

export const getSuppliersUsage = async (from: string, to: string) => {
  const response = await axios.get<SupplierUsage[]>(
    `/analytics/suppliers/usage?${encodeURI(`from=${from}&to=${to}`)}`,
  );

  return response.data;
};

export const getUnloadProductsTrend = async (from: string, to: string) => {
  const response = await axios.get(
    `/unloads/analytics/products?${encodeURI(`from=${from}&to=${to}`)}`,
  );

  return response.data;
};

export const getUnloadCategoriesTrend = async (from: string, to: string) => {
  const response = await axios.get(
    `/unloads/analytics/categories?${encodeURI(`from=${from}&to=${to}`)}`,
  );

  return response.data;
};
