import axios from 'axios';

import { endOfDay } from '@/lib/utils';
import { Invoice, InvoiceInfo } from '@/models/Invoice';
import { Supplier } from '@/models/Supplier';

export const fetchAllInvoices = async () => {
  const response = await axios.get<InvoiceInfo[]>('/invoices');
  return response.data;
};

export const getInvoiceByID = async (
  supplierID: string,
  invoiceID: number | string | undefined,
  date: string | undefined,
): Promise<Invoice> => {
  if (!invoiceID || invoiceID === 'new') {
    const getSupplier = await axios.get<Supplier>(`/suppliers/${supplierID}`);
    const supplier = getSupplier.data;

    const createdAt = date ? new Date(date) : endOfDay(new Date());

    return {
      createdAt: createdAt.toISOString(),
      isLoaded: false,
      products: [],
      pricelistID: supplier.pricelistID,
      supplierID: supplier.supplierID || 0,
      supplierName: supplier.name,
      total: 0,
    };
  }

  const response = await axios.get<Invoice>(`/invoices/${invoiceID}`);

  return response.data;
};

export const saveInvoice = async (invoice: Invoice) => {
  const { data } = await axios.post(`/invoices/${invoice.invoiceID || 'new'}`, {
    data: { invoice },
  });

  return data.invoiceID;
};

export const deleteInvoice = async (invoiceID: number) => {
  await axios.delete(`/invoices/${invoiceID}`);

  return invoiceID;
};
