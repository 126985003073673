import { useQuery } from '@tanstack/react-query';

import { getSupplierByID } from '@/lib/api/supplier';
import { Supplier } from '@/models/Supplier';

import { getSupplierQueryKey } from './queryKeys';

const useGetSupplier = (supplierId: number) => {
  return useQuery<Supplier, Error>({
    queryKey: getSupplierQueryKey(supplierId),
    queryFn: () => {
      return getSupplierByID(supplierId);
    },
  });
};

export default useGetSupplier;
