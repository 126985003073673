import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { saveInventory } from '@/lib/api/inventory';
import { Inventory } from '@/models/Inventory';

import { getInventoryQueryKey } from './queryKeys';

const useSaveInventory = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (inventory: Inventory) => saveInventory(inventory),
    onError: handleAsyncError,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getInventoryQueryKey(data) });
    },
  });
};

export default useSaveInventory;
