import { CognitoUser } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { setUser as setSentryUser } from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';

import { AMPLIFY_CONFIG } from '@/config/aws';
import { AuthTokens } from '@/models/Auth';
import { User } from '@/models/User';
import useGetCurrentUser from '@/queries/auth/useGetCurrentUser';

Amplify.configure(AMPLIFY_CONFIG);

export type AuthContextType = {
  handleLogin: (payload: { user: User; tokens: AuthTokens }) => void;
  handleLogout: () => void;
  isLoading: boolean;
  user: User | null | undefined;
  cognitoUser: CognitoUser | undefined;
  setCognitoUser: (user: CognitoUser | undefined) => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthProvider = ({ ...rest }) => {
  const [user, setUser] = useState<User | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>();

  const { isLoading, data: userData, error } = useGetCurrentUser();

  useEffect(() => {
    if (!error) return;

    console.log('Auth error:', error);
  }, [error]);

  // TODO - remove this when we have a proper user object management
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (user) {
      setSentryUser({
        id: `${user.id}`,
        email: user.email,
      });
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user: userData || user,
        cognitoUser,
        setCognitoUser,
        handleLogin: ({ user }) => {
          setUser(user);
        },
        handleLogout: () => {
          setUser(null);
          setSentryUser(null);
        },
      }}
      {...rest}
    />
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
