import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ReactNode } from 'react';

import styles from './Toast.module.scss';

export type VariantType = 'info' | 'success' | 'warning' | 'error';

type ToastMessage = ReactNode;

type ToastOptions = {
  variant?: VariantType;
  preventDuplicate?: boolean;
};

type ToastKey = string | number;

type UseToast = () => {
  showToast: (message: ToastMessage, options?: ToastOptions) => ToastKey;
  closeToast: (key?: ToastKey) => void;
};

export const useToast: UseToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return {
    showToast: enqueueSnackbar,
    closeToast: closeSnackbar,
  };
};

type CloseButtonProps = {
  toastKey: ToastKey;
};

const CloseButton = ({ toastKey }: CloseButtonProps) => {
  const { closeToast } = useToast();

  return (
    <HighlightOffIcon fontSize="small" onClick={() => closeToast(toastKey)} />
  );
};

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = (props) => (
  <SnackbarProvider
    className={styles.container}
    action={(key) => <CloseButton toastKey={key} />}
    {...props}
  />
);
