import { Paper, TableBody, TableCell } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { PricelistProductWithSupplier } from '@/models/Pricelist';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';

import CurrencyText from '../CurrencyText';

import styles from './PricelistResults.module.scss';

type PriceListResultsProps = {
  className?: string;
  products: PricelistProductWithSupplier[];
};

const PricelistResults = ({
  className,
  products = [],
}: PriceListResultsProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      className={cx(styles.container, className)}
    >
      <Table aria-label={t('pricelistResults.pricelistResultsTable')}>
        <TableHead>
          <TableRow>
            <TableHeadCell align="center">#</TableHeadCell>
            <TableHeadCell>{t('pricelistResults.product')}</TableHeadCell>
            <TableHeadCell align="center">
              {t('pricelistResults.category')}
            </TableHeadCell>
            <TableHeadCell align="center">
              {t('pricelistResults.supplier')}
            </TableHeadCell>
            <TableHeadCell align="center">
              {t('pricelistResults.pricelist')}
            </TableHeadCell>
            <TableHeadCell align="center">
              {t('pricelistResults.canvas')}
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, idx) => (
            <TableRow key={`${product.pricelistID}_${product.productID}`}>
              <TableCell style={{ width: '25px' }} align="center">
                {idx + 1}
              </TableCell>
              <TableCell
                style={{ width: '45%' }}
                className={styles.supplierName}
              >
                {product.fullName}
              </TableCell>
              <TableCell style={{ width: '10%' }} align="center">
                {product.categoryName}
              </TableCell>
              <TableCell style={{ width: '20%' }} align="center">
                {product.supplierName}
              </TableCell>
              <TableCell style={{ width: '12.5%' }} align="center">
                <CurrencyText value={product.privatePrice} />
              </TableCell>
              <TableCell style={{ width: '12.5%' }} align="center">
                <CurrencyText value={product.publicPrice} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricelistResults;
