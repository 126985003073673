import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { AnalyticsTrend } from '@/models/Analytics';
import { getColor } from '@/utils/colors';

import Container from '../components/Container';
import { TotalsBarChartTooltip } from '../components/CustomTooltip';

import styles from './TrendBarChart.module.scss';

type TrendProps = {
  data: AnalyticsTrend | null;
};

const TrendBarChart = ({ data }: TrendProps) => {
  const { t } = useTranslation();

  if (!data)
    return (
      <Container className={styles.emptyState}>
        <span className={styles.message}>{t('commons.emptyResult')}</span>
      </Container>
    );

  const chartData = data.trend.map((res) => ({
    ...res,
    fill: getColor(`${res.month}`),
    name: t('dates.dateMonthYear', {
      val: new Date(Date.UTC(res.year, res.month - 1)),
      formatParams: {
        val: {
          year: 'numeric',
          month: 'short',
        },
      },
    }),
  }));

  return (
    <Container className={styles.container}>
      <ResponsiveContainer height={250} className={styles.responsiveContainer}>
        <BarChart data={chartData} maxBarSize={20}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip cursor={false} content={<TotalsBarChartTooltip />} />
          <Bar dataKey="total" />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default TrendBarChart;
