export const getPricelistQueryKey = (pricelistId: number) => [
  'pricelist',
  pricelistId,
];

export const getFindPricelistProductQueryKey = (query: string) => [
  'pricelist',
  'search',
  'product',
  query,
];
