import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { createCustomProduct } from '@/lib/api/product';
import { NewProduct } from '@/models/Product';

import { findProductByNameQueryKey } from './queryKeys';

const useCreateCustomProduct = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (product: NewProduct) => createCustomProduct(product),
    onError: handleAsyncError,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: findProductByNameQueryKey(data.name),
      });
    },
  });
};

export default useCreateCustomProduct;
