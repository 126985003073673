import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/auth';
import { resetCurrentCognitoUser } from '@/lib/api/auth';

import { getCognitoUserQueryKey } from './queryKeys';

const useLogout = () => {
  const { handleLogout } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: resetCurrentCognitoUser,
    onError: (error: unknown) => {
      captureException(error);
    },
    onSettled: () => {
      queryClient.removeQueries({ queryKey: getCognitoUserQueryKey() });
      queryClient.clear();
      handleLogout();
    },
    throwOnError: false,
  });
};

export default useLogout;
