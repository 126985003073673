import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { deleteUnload } from '@/lib/api/unload';

import { fetchAllUnloadsQueryKey, getUnloadQueryKey } from './queryKeys';

const useDeleteUnload = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (unloadID: number) => deleteUnload(unloadID),
    onError: handleAsyncError,
    onSuccess: (unloadId) => {
      queryClient.removeQueries({ queryKey: getUnloadQueryKey(unloadId) });
      queryClient.invalidateQueries({ queryKey: fetchAllUnloadsQueryKey() });
    },
  });
};

export default useDeleteUnload;
