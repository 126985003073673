import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/context/auth';
import { signIn } from '@/lib/api/auth';
import { AuthTokens } from '@/models/Auth';
import { User } from '@/models/User';

type UseLoginParams = {
  onCredentialError?: () => void;
};

const useLogin = ({
  onCredentialError = () => console.log('Error'),
}: UseLoginParams = {}) => {
  const { handleLogin } = useAuth();

  return useMutation({
    mutationFn: signIn,
    onSuccess: (payload) => {
      if (!payload) {
        onCredentialError();
        return;
      }

      if (payload.user && payload.tokens) {
        handleLogin(payload as { user: User; tokens: AuthTokens });
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      onCredentialError();
    },
    throwOnError: false,
  });
};

export default useLogin;
