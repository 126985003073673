import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { saveInvoice } from '@/lib/api/invoice';
import { InvoiceSave } from '@/models/Invoice';

import { getInvoiceQueryKey } from './queryKeys';

const useSaveInvoice = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (invoice: InvoiceSave) => saveInvoice(invoice),
    onError: handleAsyncError,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getInvoiceQueryKey(data) });
    },
  });
};

export default useSaveInvoice;
