import { DesktopDatePicker } from '@mui/x-date-pickers';
import { endOfDay, subHours } from 'date-fns';

import styles from './DatePicker.module.scss';

type DatePickerProps = {
  label?: string;
  time?: 'startOfDay' | 'endOfDay';
  format: string;
  value: Date | null;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
};

const DatePicker = ({
  label,
  time = 'endOfDay',
  format,
  value,
  disabled = false,
  onChange,
}: DatePickerProps) => {
  const handleOnChange = (date: Date | null) => {
    if (!date) {
      onChange(date);
      return;
    }

    const formattedDate =
      time === 'endOfDay'
        ? subHours(endOfDay(date), 10)
        : subHours(endOfDay(date), 12);

    onChange(formattedDate);
  };

  return (
    <DesktopDatePicker
      label={label}
      disabled={disabled}
      format={format}
      slotProps={{ textField: { variant: 'outlined', size: 'small' } }}
      value={value}
      className={styles.container}
      onChange={handleOnChange}
    />
  );
};

export default DatePicker;
