import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CurrencyField from '@/components/CurrencyField/CurrencyField';
import SearchProductField from '@/components/SearchProductField';
import { Product } from '@/models/Product';
import Button from '@/uikit/components/Button';
import Dialog from '@/uikit/components/Dialog';

import styles from './AddPricelistProduct.module.scss';

export type DialogData = {
  product: Product;
  publicPrice: number;
  canvasPrice: number;
};

type AddPricelistProductProps = {
  isOpen: boolean;
  data?: DialogData;
  onClose: () => void;
  onSubmit: (
    product: Product,
    publicPrice: number,
    canvasPrice: number,
  ) => void;
};

export const AddPricelistProduct = ({
  isOpen,
  onClose,
  onSubmit,
  data,
}: AddPricelistProductProps) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<Product | null>(data?.product || null);
  const [publicPrice, setUnitPrice] = useState(data?.publicPrice || 0);
  const [canvasPrice, setCanvasPrice] = useState(data?.canvasPrice || 0);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setProduct(data?.product || null);
    setUnitPrice(data?.publicPrice || 0);
    setCanvasPrice(data?.canvasPrice || 0);
  }, [data]);

  const resetValues = () => {
    setProduct(null);
    setUnitPrice(0);
    setCanvasPrice(0);
  };

  const handleAddProduct = () => {
    if (product) {
      onSubmit(product, publicPrice, canvasPrice);
      resetValues();
    }
  };

  const handleClose = () => {
    resetValues();
    onClose();
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{t('addPricelistProduct.title')}</DialogTitle>
      <DialogContent className={styles.content}>
        <SearchProductField
          ref={searchFieldRef}
          className={styles.search}
          product={product}
          onChange={(item: Product | null) => {
            setProduct(item);
          }}
        />
        <CurrencyField
          fullWidth
          className={styles.publicPrice}
          label={t('addPricelistProduct.pricelistPrice')}
          value={publicPrice}
          onChange={setUnitPrice}
        />
        <CurrencyField
          fullWidth
          className={styles.canvasPrice}
          label={t('addPricelistProduct.canvasPrice')}
          value={canvasPrice}
          onChange={setCanvasPrice}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t('addPricelistProduct.cancel')}
        </Button>
        <Button color="secondary" onClick={handleAddProduct}>
          {t('addPricelistProduct.submit', {
            context: data ? 'update' : '',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
