import { BarChart } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { sub } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import StatusChip from '@/components/StatusChip';
import TableLoader from '@/components/TableLoader';
import Trends, { OnQueryParams } from '@/components/dialogs/Trends';
import UnloadCategoriesPieChart from '@/components/widgets/UnloadCategoriesPieChart';
import UnloadProductsPieChart from '@/components/widgets/UnloadProductsPieChart';
import UnloadsCategoriesBarChart from '@/components/widgets/UnloadsCategoriesBarChart';
import UnloadsProductsBarChart from '@/components/widgets/UnloadsProductsBarChart';
import useTablePagination from '@/hooks/useTablePagination';
import { AnalyticsTrend } from '@/models/Analytics';
import useGetCategoryUsage from '@/queries/category/useGetCategoryUsage';
import useGetProductUsage from '@/queries/product/useGetProductUsage';
import useFetchAllUnloads from '@/queries/unload/useFetchAllUnloads';
import Button from '@/uikit/components/Button';
import DatePicker from '@/uikit/components/DatePicker';
import IconButton from '@/uikit/components/IconButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';
import { formatDate } from '@/utils/formatDate';

import styles from './UnloadList.module.scss';

const UnloadList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(sub(new Date(), { days: 30 }));
  const [toDate, setToDate] = useState(new Date());
  const [isTrendsDialogOpen, setIsTrendsDialogOpen] = useState(false);
  const [trendData, setTrendData] = useState<AnalyticsTrend | null>(null);
  const { data: unloadList = [], isLoading } = useFetchAllUnloads();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const { mutate: getProductUsage, isPending: isLoadingByProduct } =
    useGetProductUsage();
  const { mutate: getCategoryUsage, isPending: isLoadingByCategory } =
    useGetCategoryUsage();

  const handleQuery = ({ type, fromDate, toDate, id }: OnQueryParams) => {
    switch (type) {
      case 'product':
        getProductUsage(
          {
            productId: id,
            fromDate,
            toDate,
          },
          {
            onSuccess: setTrendData,
          },
        );

        break;
      case 'category':
        getCategoryUsage(
          {
            categoryId: id,
            fromDate,
            toDate,
          },
          {
            onSuccess: setTrendData,
          },
        );

        break;
    }
  };

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.pageTitle}>
            <IconButton
              disabled
              size="small"
              aria-label={t('commons.unloadList')}
            >
              <AssignmentReturnedOutlinedIcon fontSize="inherit" />
            </IconButton>
            {t('commons.unloadList')}
          </h3>
          <div className={styles.headerActions}>
            <div className={styles.datePickersContainer}>
              <DatePicker
                label={t('commons.from')}
                format={t('dates.dateFormat')}
                value={fromDate}
                time="startOfDay"
                onChange={(value) => setFromDate(value || new Date())}
              />
              <DatePicker
                label={t('commons.to')}
                format={t('dates.dateFormat')}
                value={toDate}
                time="endOfDay"
                onChange={(value) => setToDate(value || new Date())}
              />
            </div>
            <Button
              className={styles.addButton}
              startIcon={<BarChart />}
              size="M"
              color="secondary"
              onClick={() => setIsTrendsDialogOpen(true)}
            >
              {t('unloadList.trends')}
            </Button>
            <Button
              className={styles.addButton}
              startIcon={<AddCircleIcon />}
              size="M"
              onClick={() => navigate('new')}
            >
              {t('unloadList.newUnload')}
            </Button>
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <TableLoader />
          ) : (
            <>
              <div className={styles.analytics}>
                <UnloadsProductsBarChart
                  from={fromDate.toISOString()}
                  to={toDate.toISOString()}
                />
                <UnloadProductsPieChart
                  from={fromDate.toISOString()}
                  to={toDate.toISOString()}
                />
                <UnloadsCategoriesBarChart
                  from={fromDate.toISOString()}
                  to={toDate.toISOString()}
                />
                <UnloadCategoriesPieChart
                  from={fromDate.toISOString()}
                  to={toDate.toISOString()}
                />
              </div>
              <TableContainer
                component={Paper}
                className={styles.tableContainer}
              >
                <Table aria-label={t('unloadList.unloadListTable')}>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell align="center">#</TableHeadCell>
                      <TableHeadCell>{t('unloadList.date')}</TableHeadCell>
                      <TableHeadCell>
                        {t('unloadList.lastUpdate')}
                      </TableHeadCell>
                      <TableHeadCell align="center">
                        {t('unloadList.status')}
                      </TableHeadCell>
                      <TableHeadCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? unloadList.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                      : unloadList
                    ).map((unload, idx) => (
                      <TableRow
                        key={unload.unloadID}
                        clickable
                        onClick={() => navigate(`${unload.unloadID}`)}
                      >
                        <TableCell style={{ width: 50 }} align="center">
                          {idx + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell style={{ width: '35%' }}>
                          {formatDate(unload.createdAt, 'EEEE - PP')}
                        </TableCell>
                        <TableCell style={{ width: '35%' }}>
                          {unload.updatedAt
                            ? formatDate(unload.updatedAt, 'EEEE - PP')
                            : ''}
                        </TableCell>
                        <TableCell
                          style={{ width: '20%', padding: '0 16px' }}
                          align="center"
                        >
                          <StatusChip isLoaded={unload.isLoaded} />
                        </TableCell>
                        <TableCell
                          style={{ width: '10%', padding: '0 16px' }}
                          className={styles.tableCTA}
                          align="center"
                        >
                          <IconButton
                            color={unload.isLoaded ? 'primary' : 'secondary'}
                          >
                            <ContentPasteSearchIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        showFirstButton
                        showLastButton
                        rowsPerPageOptions={[25, 50, 100]}
                        colSpan={5}
                        count={unloadList?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': t('commons.rowPerPage'),
                          },
                          native: true,
                        }}
                        labelRowsPerPage={t('commons.rowPerPage')}
                        onPageChange={(_event, newPage) => {
                          setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) =>
                          updateRowsPerPage(event.target.value)
                        }
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
        </Body>
        <Trends
          title={`${t('unloadList.productUsage')}`}
          isOpen={isTrendsDialogOpen}
          isLoading={isLoadingByProduct || isLoadingByCategory}
          data={trendData}
          onClose={() => setIsTrendsDialogOpen(false)}
          onQuery={handleQuery}
        />
      </>
    </Page>
  );
};

export default UnloadList;
