import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './TableLoader.module.scss';

const TableLoader: React.FC = () => {
  return (
    <>
      <Skeleton
        height={56.5}
        baseColor="#2E6D92"
        highlightColor="#525981"
        className={styles.header}
      />
      <Skeleton count={10} height={53} baseColor="#FFF" inline={false} />
    </>
  );
};

export default TableLoader;
