import { useMutation, useQueryClient } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { savePricelist } from '@/lib/api/pricelist';
import { Pricelist } from '@/models/Pricelist';

import { getPricelistQueryKey } from './queryKeys';

const useSavePricelist = () => {
  const handleAsyncError = useOnAsyncError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (pricelist: Pricelist) => savePricelist(pricelist),
    onError: handleAsyncError,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: getPricelistQueryKey(data.pricelistID),
      });
    },
  });
};

export default useSavePricelist;
