import { useQuery } from '@tanstack/react-query';

import { getUnloadByID } from '@/lib/api/unload';
import { endOfDay } from '@/lib/utils';
import { Unload } from '@/models/Unload';

import { getUnloadQueryKey } from './queryKeys';

const useGetUnload = (unloadId: string) => {
  return useQuery<Unload, Error>({
    queryKey: getUnloadQueryKey(unloadId),
    queryFn: () => {
      if (!unloadId || unloadId === 'new') {
        return {
          createdAt: endOfDay(new Date()).toISOString(),
          isLoaded: false,
          products: [],
        };
      }

      return getUnloadByID(Number(unloadId));
    },
  });
};

export default useGetUnload;
