import { useQuery } from '@tanstack/react-query';

import { getSuppliersUsage } from '@/lib/api/analytics';
import { SupplierUsage } from '@/models/Analytics';

import { getSuppliersUsageQueryKey } from './queryKeys';

const useGetSupplierUsage = (from: string, to: string) => {
  return useQuery<SupplierUsage[], Error>({
    queryKey: getSuppliersUsageQueryKey(from, to),
    queryFn: () => getSuppliersUsage(from, to),
  });
};

export default useGetSupplierUsage;
