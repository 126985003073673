import axios from 'axios';

import { Inventory, InventoryInfo } from '@/models/Inventory';
import { StockProduct } from '@/models/StockProduct';

export const fetchAllInventories = async () => {
  const response = await axios.get<InventoryInfo[]>('/counting');
  return response.data;
};

export const getInventoryByID = async (inventoryID: string) => {
  const response = await axios.get<Inventory>(`/counting/${inventoryID}`);

  return response.data;
};

export const getNewInventory = async (): Promise<Inventory> => {
  const response = await axios.get<StockProduct[]>('/stock');

  return {
    createdAt: new Date().toISOString(),
    isLoaded: false,
    products: response.data.filter((prod) => prod.quantity > 0),
  };
};

export const saveInventory = async (inventory: Inventory) => {
  await axios.post(`/counting/${inventory.countingID || 'new'}`, {
    data: { counting: inventory },
  });

  return inventory.countingID;
};

export const deleteInventory = async (inventoryID: number) => {
  await axios.delete(`/counting/${inventoryID}`);

  return inventoryID;
};
