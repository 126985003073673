import axios from 'axios';

import { Pricelist, PricelistProductWithSupplier } from '@/models/Pricelist';

import { formatSearchName } from '../utils';

export const getPricelistByID = async (pricelistID: number) => {
  const response = await axios.get<Pricelist>(`/pricelist/${pricelistID}`);

  return response.data;
};

export const savePricelist = async (pricelist: Pricelist) => {
  const response = await axios.post<Pricelist>(`/pricelist`, {
    data: { pricelist },
  });

  return response.data;
};

export const deletePricelist = async (pricelistID: number) => {
  await axios.delete(`/pricelist/${pricelistID}`);

  return pricelistID;
};

export const findPricelistProduct = async (query: string) => {
  const encoded = encodeURI(formatSearchName(query));
  const response = await axios.get<PricelistProductWithSupplier[]>(
    `/pricelist/product/${encoded}`,
  );

  return response.data;
};
