import styles from './CustomTooltip.module.scss';

// @ts-ignore: check params typing
const PercentagePieChartTooltip = ({ active, payload }: unknown) => {
  if (active && payload?.length) {
    return (
      <div className={styles.container}>
        <p className={styles.label}>{`${payload[0].name}`}</p>
        <p className={styles.value}>{`${payload[0].value.toFixed(2)}%`}</p>
      </div>
    );
  }

  return null;
};

export default PercentagePieChartTooltip;
