import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Category } from '@/models/Category';
import { UnitOfMeasure } from '@/models/Product';
import useFetchAllCategories from '@/queries/category/useFetchAllCategories';
import useCreateCustomProduct from '@/queries/product/useCreateCustomProduct';
import Button from '@/uikit/components/Button';
import Dialog from '@/uikit/components/Dialog';
import LoadingButton from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';
import { unitOfMeasureList } from '@/utils/category';

import styles from './NewProduct.module.scss';

const NewProduct = ({
  initialName,
  isOpen,
  onClose,
}: {
  initialName?: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [size, setSize] = useState(75);
  const [unitOfMeasure, setUnitOfMeasure] = useState<UnitOfMeasure>(
    UnitOfMeasure.CL,
  );
  const [category, setCategory] = useState<Category | null>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const unitOfMeasureRef = useRef<HTMLInputElement>(null);

  const { data: categoryList = [] } = useFetchAllCategories();
  const {
    mutate: createProduct,
    isPending,
    isSuccess,
  } = useCreateCustomProduct();

  const handleCreateProduct = () => {
    createProduct({
      categoryID: category?.categoryID,
      size,
      name,
      um: unitOfMeasure,
    });
  };

  useEffect(() => {
    if (initialName) {
      setName(initialName);
    }
  }, [initialName]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogTitle>{t('newProduct.title')}</DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          <TextField
            autoFocus
            fullWidth
            required
            className={styles.name}
            label={t('newProduct.name')}
            value={name}
            placeholder={t('newProduct.namePlaceholder')}
            onChange={(event) => setName(event.target.value || '')}
          />
          <TextField
            fullWidth
            className={styles.size}
            label={t('newProduct.size')}
            value={size}
            placeholder={t('newProduct.sizePlaceholder')}
            onChange={(event) => setSize(Number(event.target.value))}
            onBlur={() => setSize(size || 75)}
          />
          <Autocomplete
            disableClearable
            autoHighlight
            className={styles.um}
            options={unitOfMeasureList}
            filterOptions={(x) => x}
            value={unitOfMeasure}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputRef={unitOfMeasureRef}
                label={t('newProduct.unitOfMeasure')}
                placeholder={t('newProduct.unitOfMeasurePlaceholder')}
                variant="outlined"
                margin="none"
              />
            )}
            onChange={(_event, value) => value && setUnitOfMeasure(value)}
          />
          <Autocomplete
            autoHighlight
            className={styles.category}
            options={categoryList}
            value={category}
            getOptionLabel={(option: Category) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputRef={categoryRef}
                label={t('newProduct.category')}
                placeholder={t('newProduct.categoryPlaceholder')}
                variant="outlined"
                margin="none"
              />
            )}
            onChange={(_event, value) => value && setCategory(value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isPending}
          onClick={onClose}
        >
          {t('newProduct.cancel')}
        </Button>
        <LoadingButton
          disabled={!name}
          color="secondary"
          loading={isPending}
          onClick={handleCreateProduct}
        >
          {t('newProduct.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewProduct;
