import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { COGNITO_CHALLENGE_REQUIRE_PASSWORD } from '@/config/constant';
import { useAuth } from '@/context/auth';
import useLogin from '@/queries/auth/useLogin';
import Button from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';

import styles from './Login.module.scss';

const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { mutate: loginUser, isPending } = useLogin();
  const navigate = useNavigate();

  const { user, setCognitoUser } = useAuth();

  useEffect(() => {
    if (user) navigate('/');
  }, [navigate, user]);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    loginUser(
      { username, password },
      {
        onSuccess: (params) => {
          if (
            params?.cognitoUser?.challengeName ===
            COGNITO_CHALLENGE_REQUIRE_PASSWORD
          ) {
            setCognitoUser(params.cognitoUser);

            return navigate('/update-password', { replace: true });
          }

          return navigate('/');
        },
      },
    );
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.title}>deedit</h1>
        <TextField
          type="email"
          required
          placeholder={t('auth.username')}
          value={username}
          autoComplete="username"
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          required
          placeholder={t('auth.password')}
          type="password"
          value={password}
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          size="L"
          className={styles.submit}
          type="submit"
          value="Submit"
          loading={isPending}
        >
          {t('auth.login')}
        </Button>
      </form>
    </div>
  );
};

export default Login;
