import MUIMenu, { MenuProps } from '@mui/material/Menu';
import cx from 'classnames';

import styles from './Menu.module.scss';

const Menu = ({ className, ...props }: MenuProps) => {
  return <MUIMenu className={cx(styles.container, className)} {...props} />;
};

export default Menu;
