export const getSuppliersUsageQueryKey = (from: string, to: string) => [
  'analytics',
  'suppliers',
  'usage',
  from,
  to,
];

export const getUnloadProductsTrendQueryKey = (from: string, to: string) => [
  'analytics',
  'unload',
  'products',
  from,
  to,
];

export const getUnloadCategoriesTrendQueryKey = (from: string, to: string) => [
  'analytics',
  'unload',
  'categories',
  from,
  to,
];
