import { useTranslation } from 'react-i18next';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import CurrencyText from '@/components/CurrencyText';
import useGetSupplierUsage from '@/queries/analytics/useGetSuppliersUsage';
import { getColor } from '@/utils/colors';

import Container from '../components/Container';
import { PercentagePieChartTooltip } from '../components/CustomTooltip';
import Loader from '../components/Loader';

import styles from './SupplierUsagePieChat.module.scss';

type SupplierUsagePieChartProps = { from?: string; to?: string };

const defaultFrom: string = new Date(new Date().getDate() - 7).toISOString();
const defaultTo: string = new Date().toISOString();

const SupplierUsagePieChat = ({
  from = defaultFrom,
  to = defaultTo,
}: SupplierUsagePieChartProps) => {
  const { t } = useTranslation();
  const { data: result } = useGetSupplierUsage(from, to);

  if (!result) {
    return <Loader />;
  }

  const data = result.map((res) => ({
    ...res,
    fill: getColor(res.name),
    label: res.name,
  }));

  const total = data
    .reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)
    .toFixed(2);

  return (
    <Container className={styles.container}>
      <div className={styles.legend}>
        <label className={styles.main}>
          <CurrencyText value={total} />
        </label>
        <h5 className={styles.subtitle}>{t('widgets.overall')}</h5>
      </div>
      <ResponsiveContainer
        width="100%"
        height={150}
        className={styles.responsiveContainer}
      >
        <PieChart>
          <Pie
            cornerRadius={10}
            cy={70}
            data={data}
            dataKey="percentage"
            innerRadius={55}
            outerRadius={65}
            paddingAngle={1}
          />
          <Tooltip content={<PercentagePieChartTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default SupplierUsagePieChat;
