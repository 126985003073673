import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';

import Account from '@/components/Account';
import { useAuth } from '@/context/auth';

import styles from './AppLayout.module.scss';

const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuth();

  return (
    <div className={styles.container}>
      <div className={styles.branding}>{user?.companies[0]?.name}</div>
      <div className={styles.sidebar}>
        <div className={styles.logoContainer}>
          <Avatar
            alt={user?.companies[0]?.name || undefined}
            src={user?.companies[0]?.logo || undefined}
            sx={{ width: 60, height: 60, backgroundColor: 'black' }}
          />
        </div>
        <List className={styles.list}>
          <ListItemButton
            selected={pathname === '/'}
            component={Link}
            to="/"
            classes={{
              root: styles.listItemButton,
              selected: styles.listItemButtonSelected,
            }}
          >
            <ListItem>
              <ListItemIcon classes={{ root: styles.listItemIcon }}>
                <InventoryOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{t('commons.stock')}</ListItemText>
            </ListItem>
          </ListItemButton>
          <ListItemButton
            selected={pathname === '/invoices'}
            component={Link}
            to="/invoices"
            classes={{
              root: styles.listItemButton,
              selected: styles.listItemButtonSelected,
            }}
          >
            <ListItem>
              <ListItemIcon classes={{ root: styles.listItemIcon }}>
                <ReceiptOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{t('commons.invoices')}</ListItemText>
            </ListItem>
          </ListItemButton>
          <ListItemButton
            selected={pathname === '/unloads'}
            component={Link}
            to="/unloads"
            classes={{
              root: styles.listItemButton,
              selected: styles.listItemButtonSelected,
            }}
          >
            <ListItem>
              <ListItemIcon classes={{ root: styles.listItemIcon }}>
                <AssignmentReturnedOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{t('commons.unloads')}</ListItemText>
            </ListItem>
          </ListItemButton>
          <ListItemButton
            selected={pathname === '/suppliers'}
            component={Link}
            to="/suppliers"
            classes={{
              root: styles.listItemButton,
              selected: styles.listItemButtonSelected,
            }}
          >
            <ListItem>
              <ListItemIcon classes={{ root: styles.listItemIcon }}>
                <LocalMallOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{t('commons.suppliers')}</ListItemText>
            </ListItem>
          </ListItemButton>
          <ListItemButton
            selected={pathname === '/orders'}
            component={Link}
            to="/orders"
            classes={{
              root: styles.listItemButton,
              selected: styles.listItemButtonSelected,
            }}
          >
            <ListItem>
              <ListItemIcon classes={{ root: styles.listItemIcon }}>
                <DeliveryDiningOutlinedIcon />
              </ListItemIcon>
              <ListItemText>{t('commons.orders')}</ListItemText>
            </ListItem>
          </ListItemButton>
        </List>
        <Account className={styles.account} />
      </div>
      <div className={styles.page}>{<Outlet />}</div>
    </div>
  );
};

export default AppLayout;
