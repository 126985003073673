import { BarChart } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CurrencyText from '@/components/CurrencyText';
import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import StatusChip from '@/components/StatusChip';
import TableLoader from '@/components/TableLoader';
import NewInvoice from '@/components/dialogs/NewInvoice';
import Trends, { OnQueryParams } from '@/components/dialogs/Trends';
import SupplierUsageBarChart from '@/components/widgets/SupplierUsageBarChart';
import SupplierUsagePieChat from '@/components/widgets/SupplierUsagePieChart/SupplierUsagePieChat';
import useTablePagination from '@/hooks/useTablePagination';
import { AnalyticsTrend } from '@/models/Analytics';
import useGetCategoryEntries from '@/queries/category/useGetCategoryEntries';
import useFetchAllInvoices from '@/queries/invoice/useFetchAllInvoices';
import useSaveInvoice from '@/queries/invoice/useSaveInvoice';
import useGetProductEntries from '@/queries/product/useGetProductEntries';
import Button from '@/uikit/components/Button';
import DatePicker from '@/uikit/components/DatePicker';
import IconButton from '@/uikit/components/IconButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';
import { formatDate } from '@/utils/formatDate';

import styles from './InvoiceList.module.scss';

const InvoiceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(
    startOfDay(sub(new Date(), { days: 30 })),
  );
  const [toDate, setToDate] = useState(endOfDay(new Date()));
  const [isTrendsDialogOpen, setIsTrendsDialogOpen] = useState(false);
  const { data: invoiceList = [], isLoading } = useFetchAllInvoices();
  const { mutate: saveInvoice, isPending: isCreating } = useSaveInvoice();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [trendData, setTrendData] = useState<AnalyticsTrend | null>(null);
  const { mutate: getProductEntries, isPending: isProductEntriesLoading } =
    useGetProductEntries();
  const { mutate: getCategoryEntries, isPending: isCategoryEntriesLoading } =
    useGetCategoryEntries();

  const handleQuery = ({ type, fromDate, toDate, id }: OnQueryParams) => {
    switch (type) {
      case 'product':
        getProductEntries(
          {
            productId: id,
            fromDate,
            toDate,
          },
          {
            onSuccess: setTrendData,
          },
        );

        break;
      case 'category':
        getCategoryEntries(
          {
            categoryId: id,
            fromDate,
            toDate,
          },
          {
            onSuccess: setTrendData,
          },
        );

        break;
    }
  };

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.pageTitle}>
            <IconButton disabled size="small" aria-label={t('commons.invoice')}>
              <ReceiptOutlinedIcon fontSize="inherit" />
            </IconButton>
            {t('commons.invoiceList')}
          </h3>
          <div className={styles.headerActions}>
            <div className={styles.datePickersContainer}>
              <DatePicker
                label={t('commons.from')}
                format={t('dates.dateFormat')}
                time="startOfDay"
                value={fromDate}
                onChange={(value) => setFromDate(value || new Date())}
              />
              <DatePicker
                label={t('commons.to')}
                format={t('dates.dateFormat')}
                value={toDate}
                time="endOfDay"
                onChange={(value) => setToDate(value || new Date())}
              />
            </div>
            <Button
              className={styles.addButton}
              startIcon={<BarChart />}
              size="M"
              color="secondary"
              onClick={() => setIsTrendsDialogOpen(true)}
            >
              {t('unloadList.trends')}
            </Button>

            <Button
              className={styles.addButton}
              startIcon={<AddCircleIcon />}
              size="M"
              onClick={() => setIsDialogOpen(true)}
            >
              {t('invoiceList.newInvoice')}
            </Button>
          </div>
        </Header>
        <Body>
          <div className={styles.analytics}>
            <SupplierUsagePieChat
              from={fromDate.toISOString()}
              to={toDate.toISOString()}
            />
            <SupplierUsageBarChart
              from={fromDate.toISOString()}
              to={toDate.toISOString()}
            />
          </div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('invoiceList.invoiceListTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('invoiceList.date')}</TableHeadCell>
                    <TableHeadCell>{t('invoiceList.lastUpdate')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoiceList.supplier')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoiceList.amount')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('invoiceList.status')}
                    </TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? invoiceList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : invoiceList
                  ).map((invoice, idx) => (
                    <TableRow
                      key={invoice.invoiceID}
                      clickable
                      onClick={() =>
                        navigate(
                          `${invoice.invoiceID}/supplier/${invoice.supplierID}`,
                        )
                      }
                    >
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ width: '20%' }}>
                        {formatDate(invoice.createdAt, 'EEEE - PP')}
                      </TableCell>
                      <TableCell style={{ width: '20%' }}>
                        {invoice.updatedAt
                          ? formatDate(invoice.updatedAt, 'EEEE - PP')
                          : ''}
                      </TableCell>
                      <TableCell
                        style={{ width: '20%' }}
                        align="center"
                        className={styles.supplierName}
                      >
                        {invoice.supplierName}
                      </TableCell>
                      <TableCell style={{ width: '20%' }} align="center">
                        <CurrencyText value={invoice.total} />
                      </TableCell>
                      <TableCell
                        style={{ width: '12%', padding: '0 16px' }}
                        align="center"
                      >
                        <StatusChip isLoaded={invoice.isLoaded} />
                      </TableCell>
                      <TableCell
                        style={{ width: '8%', padding: '0 16px' }}
                        className={styles.tableCTA}
                        align="center"
                      >
                        <IconButton
                          color={invoice.isLoaded ? 'primary' : 'secondary'}
                        >
                          <ContentPasteSearchIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={7}
                      count={invoiceList?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>
        <Trends
          title={`${t('invoiceList.incomingProducts')}`}
          isOpen={isTrendsDialogOpen}
          isLoading={isProductEntriesLoading || isCategoryEntriesLoading}
          data={trendData}
          onClose={() => setIsTrendsDialogOpen(false)}
          onQuery={handleQuery}
        />
        <NewInvoice
          isOpen={isDialogOpen}
          isLoading={isCreating}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          onSubmit={(supplier, date) => {
            if (!supplier.supplierID) return;
            saveInvoice(
              {
                supplierID: supplier.supplierID,
                createdAt: date.toISOString(),
                products: [],
                isLoaded: false,
                pricelistID: supplier.pricelistID,
                supplierName: supplier.name,
              },
              {
                onSuccess: (invoiceID) => {
                  navigate(
                    `/invoices/${invoiceID}/supplier/${supplier.supplierID}`,
                  );
                },
              },
            );
          }}
        />
      </>
    </Page>
  );
};

export default InvoiceList;
