import { useTranslation } from 'react-i18next';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { AnalyticsTrend } from '@/models/Analytics';
import { getColor } from '@/utils/colors';

import Container from '../components/Container';
import { PercentagePieChartTooltip } from '../components/CustomTooltip';

import styles from './TrendPieChart.module.scss';

type TrendProps = {
  data: AnalyticsTrend | null;
};

const TrendPieChart = ({ data }: TrendProps) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <Container className={styles.emptyState}>
        <span className={styles.message}>{t('commons.emptyPieChart')}</span>
      </Container>
    );
  }

  const chartData = data.trend.map((res) => ({
    ...res,
    percentage: data.total ? (res.total / data.total) * 100 : 0,
    fill: getColor(`${res.month}`),
    name: t('dates.dateMonthYear', {
      val: new Date(Date.UTC(res.year, res.month - 1)),
      formatParams: {
        val: {
          year: 'numeric',
          month: 'short',
        },
      },
    }),
  }));

  return (
    <Container className={styles.container}>
      <div className={styles.total}>
        <p>{data.total}</p>
        <p className={styles.subtitle}>{t('widgets.totalItems')}</p>
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart width={400} height={400}>
          <Pie
            cornerRadius={10}
            cy={110}
            data={chartData}
            dataKey="percentage"
            innerRadius={55}
            outerRadius={65}
            paddingAngle={1}
          />
          <Tooltip content={<PercentagePieChartTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default TrendPieChart;
