import { useQuery } from '@tanstack/react-query';

import { findPricelistProduct } from '@/lib/api/pricelist';
import { PricelistProductWithSupplier } from '@/models/Pricelist';

import { getFindPricelistProductQueryKey } from './queryKeys';

const useFindPricelistProduct = (query: string) => {
  return useQuery<PricelistProductWithSupplier[], Error>({
    queryKey: getFindPricelistProductQueryKey(query),
    queryFn: () => {
      return findPricelistProduct(query);
    },
    enabled: !!query,
  });
};

export default useFindPricelistProduct;
