import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactNode } from 'react';

import useOnAsyncError from '@/hooks/useOnAsyncError';

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
let onError = (_error: unknown) => {};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      throwOnError: true,
      // onError reference will change on re-renders so we need to wrap it
      // within an arrow function
      onError: (error) => onError(error),
    },
  },
});

export const QueryClientProvider = (props: { children: ReactNode }) => {
  onError = useOnAsyncError();

  return <ReactQueryClientProvider client={queryClient} {...props} />;
};
