import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LoadingState from '@/components/LoadingState';
import ProtectedRoute from '@/components/ProtectedRoute';
import AppLayout from '@/components/layout/AppLayout';
import '@/config/axios';
import { useAuth } from '@/context/auth';
import Login from '@/pages/auth/Login';
import UpdatePassword from '@/pages/auth/UpdatePassword';
import InventoryList from '@/pages/inventoryList';
import InventoryPage from '@/pages/inventoryPage';
import InvoiceList from '@/pages/invoiceList';
import InvoicePage from '@/pages/invoicePage';
import OrderList from '@/pages/orderList';
import Stock from '@/pages/stock';
import SupplierList from '@/pages/supplierList';
import SupplierPage from '@/pages/supplierPage';
import UnloadList from '@/pages/unloadList';
import UnloadPage from '@/pages/unloadPage';

const AppRoutes = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route element={<ProtectedRoute isAllowed={!!user} />}>
          <Route element={<AppLayout />}>
            <Route index element={<Stock />} />
            <Route path="/inventory" element={<InventoryList />} />
            <Route path="/inventory/:inventoryID" element={<InventoryPage />} />
            <Route path="/invoices" element={<InvoiceList />} />
            <Route
              path="/invoices/:invoiceID/supplier/:supplierID"
              element={<InvoicePage />}
            />
            <Route path="/unloads" element={<UnloadList />} />
            <Route path="/unloads/:unloadID" element={<UnloadPage />} />
            <Route path="/suppliers" element={<SupplierList />} />
            <Route
              path="/suppliers/:supplierID/pricelist/:pricelistID"
              element={<SupplierPage />}
            />
            <Route path="/orders" element={<OrderList />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
