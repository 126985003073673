import axios from 'axios';

import { Supplier } from '@/models/Supplier';

export const fetchAllSuppliers = async () => {
  const response = await axios.get<Supplier[]>('/suppliers');

  return response.data;
};

export const getSupplierByID = async (supplierID: number) => {
  const response = await axios.get<Supplier>(`/suppliers/${supplierID}`);

  return response.data;
};

export const getSupplierProduct = async (
  supplierID: number,
  productID: number,
) => {
  const response = await axios.get(
    `/suppliers/${supplierID}/product/${productID}`,
  );

  return response.data;
};

export const saveSupplier = async (
  supplier: Omit<Supplier, 'supplierID' | 'pricelistID'>,
) => {
  const response = await axios.post<Supplier>('/suppliers', {
    data: {
      ...supplier,
    },
  });

  return response.data;
};
