import AddIcon from '@mui/icons-material/Add';

import Fab from '@/uikit/components/Fab';

import styles from './AddItem.module.scss';

const AddItemButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Fab color="primary" className={styles.addItemButton} onClick={onClick}>
      <AddIcon />
    </Fab>
  );
};

export default AddItemButton;
