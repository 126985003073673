import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({
  isAllowed = false,
  redirectPath = '/login',
  children,
}: {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}) => {
  if (!isAllowed) {
    return <Navigate replace to={redirectPath} />;
  }

  return children ? <>children</> : <Outlet />;
};

export default ProtectedRoute;
