import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import TableLoader from '@/components/TableLoader';
import useSearchableProducts from '@/hooks/useSearchableProducts';
import useTablePagination from '@/hooks/useTablePagination';
import useFetchStock from '@/queries/stock/useFetchStock';
import Button from '@/uikit/components/Button';
import IconButton from '@/uikit/components/IconButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TableRow from '@/uikit/components/TableRow';
import TextField from '@/uikit/components/TextField';

import styles from './Stock.module.scss';

const Stock = () => {
  const { t } = useTranslation();
  const { data: stockProducts = [], isLoading, isSuccess } = useFetchStock();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const {
    result: tableProducts,
    setSearchQuery,
    searchQuery,
    setProductsStore,
  } = useSearchableProducts(stockProducts);

  const handleSearchProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      setProductsStore(stockProducts);
    }
  }, [stockProducts, isSuccess, setProductsStore]);

  return (
    <Page>
      <>
        <Header>
          <IconButton disabled size="small" aria-label={t('commons.stock')}>
            <InventoryOutlinedIcon fontSize="inherit" />
          </IconButton>
          <h3>{t('commons.stock')}</h3>
          <div className={styles.headerActions}>
            <TextField
              className={styles.searchTextField}
              placeholder={t('commons.searchProduct')}
              type="search"
              value={searchQuery}
              size="small"
              onChange={handleSearchProduct}
            />
            <Button
              startIcon={<AppRegistrationRoundedIcon />}
              size="M"
              href="/inventory"
            >
              {t('stock.inventory')}
            </Button>
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label={t('stock.stockTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('stock.product')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('stock.category')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('stock.quantity')}
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? tableProducts.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : tableProducts
                  ).map((product, idx) => (
                    <TableRow key={product.productID}>
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell>{product.fullName}</TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {product.categoryName}
                      </TableCell>
                      <TableCell style={{ width: 120 }} align="center">
                        {product.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={4}
                      count={tableProducts?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>
      </>
    </Page>
  );
};

export default Stock;
