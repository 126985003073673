import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AddItemButton from '@/components/AddItemButton';
import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import QuantityField from '@/components/QuantityField/QuantityField';
import TableLoader from '@/components/TableLoader';
import AddProductQuantity from '@/components/dialogs/AddProductQuantity';
import useSearchableProducts from '@/hooks/useSearchableProducts';
import useTablePagination from '@/hooks/useTablePagination';
import { endOfDay } from '@/lib/utils';
import { Product } from '@/models/Product';
import useDeleteUnload from '@/queries/unload/useDeleteUnload';
import useGetUnload from '@/queries/unload/useGetUnload';
import useSaveUnload from '@/queries/unload/useSaveUnload';
import DatePicker from '@/uikit/components/DatePicker';
import IconButton from '@/uikit/components/IconButton';
import LoadingButton from '@/uikit/components/LoadingButton';
import Menu from '@/uikit/components/Menu';
import MenuItem from '@/uikit/components/MenuItem';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TextField from '@/uikit/components/TextField';

import styles from './UnloadPage.module.scss';

const UnloadPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { unloadID } = useParams();
  const { data: unload, isLoading, isSuccess } = useGetUnload(unloadID || '');
  const { mutate: saveUnload, isPending: isSaving } = useSaveUnload();
  const { mutate: deleteUnload, isPending: isDeleting } = useDeleteUnload();
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const [date, setDate] = useState<Date | null>(
    unload?.createdAt ? new Date(unload.createdAt) : endOfDay(new Date()),
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const {
    result: searchResult,
    updateProduct,
    addProduct,
    deleteProduct,
    setSearchQuery,
    searchQuery,
    setProductsStore,
    allProducts,
  } = useSearchableProducts(unload?.products || []);

  const handleQuantityChange = (productID: number, quantity: number) => {
    const result = searchResult.find(
      (product) => product.productID === productID,
    );

    if (result) {
      updateProduct({ ...result, quantity });
    }
  };

  const handleAddProduct = (newProduct: Product, quantity: number) => {
    addProduct({ ...newProduct, quantity });
    setIsDialogOpen(false);
  };

  const handleSaveUnload = () => {
    saveUnload(
      {
        unloadID: unload?.unloadID,
        createdAt: date?.toISOString() || '',
        isLoaded: true,
        products: allProducts,
      },
      {
        onSuccess: () => {
          navigate(-1);
        },
      },
    );
  };

  const handleSearchProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleDeleteUnload = () => {
    if (unload?.unloadID) {
      deleteUnload(unload.unloadID);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isSuccess && unload) {
      setDate(new Date(unload.createdAt));
      setProductsStore(unload.products || []);
    }
  }, [unload, isSuccess, setProductsStore]);

  return (
    <Page>
      <>
        <Header>
          <h3 className={styles.pageTitle}>
            <IconButton
              size="small"
              aria-label={t('commons.unload')}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            {t('commons.unload')}
          </h3>
          <div className={styles.headerActions}>
            {isLoading ? (
              <Skeleton
                width={230}
                style={{ lineHeight: '66px', borderRadius: '8px' }}
              />
            ) : (
              <DatePicker
                format={t('dates.dateFormat')}
                value={date}
                onChange={(value) => setDate(value)}
              />
            )}
            <TextField
              className={styles.searchTextField}
              placeholder={t('commons.searchProduct')}
              size="small"
              value={searchQuery}
              onChange={handleSearchProduct}
            />
            <div className={styles.buttons}>
              <LoadingButton
                loading={isSaving || isDeleting}
                startIcon={<DoneIcon />}
                onClick={handleSaveUnload}
              >
                {t('commons.save')}
              </LoadingButton>
              <div>
                <IconButton
                  id="more-menu"
                  aria-controls={openMenu ? 'more-unload-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={(event) => {
                    setMenuAnchorEl(event.currentTarget);
                  }}
                  color="primary"
                  disabled={isDeleting}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="more-unload-menu"
                  anchorEl={menuAnchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'more-menu',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={handleDeleteUnload}>
                    <ListItemIcon>
                      <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('commons.delete')}</ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('unload.unloadTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('unload.product')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('unload.category')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('unload.quantity')}
                    </TableHeadCell>
                    <TableHeadCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? searchResult.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : searchResult
                  ).map((product, idx) => (
                    <TableRow key={product.productID}>
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ width: '50%' }}>
                        {product.fullName}
                      </TableCell>
                      <TableCell style={{ width: '30%' }} align="center">
                        {product.categoryName}
                      </TableCell>
                      <TableCell
                        style={{ width: '15%', padding: '0 16px' }}
                        align="center"
                      >
                        <QuantityField
                          size="small"
                          value={product.quantity}
                          margin="none"
                          inputProps={{ style: { textAlign: 'center' } }}
                          onChange={(value: number) => {
                            handleQuantityChange(product.productID, value);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{ width: 50, padding: '0 10px 0 0' }}
                        align="center"
                      >
                        <IconButton
                          size="small"
                          aria-label={t('commons.stock')}
                          color="error"
                          className={styles.deleteItemIcon}
                          onClick={() => deleteProduct(product.productID)}
                        >
                          <DeleteOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={5}
                      count={searchResult.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>

        <AddItemButton
          onClick={() => {
            setIsDialogOpen(true);
          }}
        />

        <AddProductQuantity
          title={t('unload.dialogTitle')}
          isOpen={isDialogOpen}
          onSubmit={handleAddProduct}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    </Page>
  );
};

export default UnloadPage;
