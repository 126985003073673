import { useMutation } from '@tanstack/react-query';

import useOnAsyncError from '@/hooks/useOnAsyncError';
import { getProductEntries } from '@/lib/api/product';

const useGetProductEntries = () => {
  const handleAsyncError = useOnAsyncError();

  return useMutation({
    mutationFn: ({
      productId,
      fromDate,
      toDate,
    }: {
      productId: number;
      fromDate: Date;
      toDate: Date;
    }) => {
      const from = fromDate.toISOString();
      const to = toDate.toISOString();

      return getProductEntries(productId, from, to);
    },
    onError: handleAsyncError,
  });
};

export default useGetProductEntries;
