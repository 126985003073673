import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import {
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AddItemButton from '@/components/AddItemButton';
import CurrencyField from '@/components/CurrencyField/CurrencyField';
import Page from '@/components/Page';
import Body from '@/components/Page/Body';
import Header from '@/components/Page/Header';
import TableLoader from '@/components/TableLoader';
import { AddPricelistProduct } from '@/components/dialogs/AddPricelistProduct';
import useSearchableProducts from '@/hooks/useSearchableProducts';
import useTablePagination from '@/hooks/useTablePagination';
import { Product } from '@/models/Product';
import useGetPricelist from '@/queries/pricelist/useGetPricelist';
import useSavePricelist from '@/queries/pricelist/useSavePricelist';
import useGetSupplier from '@/queries/supplier/useGetSupplier';
import IconButton from '@/uikit/components/IconButton';
import LoadingButton from '@/uikit/components/LoadingButton';
import Table from '@/uikit/components/Table';
import TableContainer from '@/uikit/components/TableContainer';
import TableHead from '@/uikit/components/TableHead';
import TableHeadCell from '@/uikit/components/TableHeadCell';
import TextField from '@/uikit/components/TextField';

import styles from './SupplierPage.module.scss';

const SupplierPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { supplierID, pricelistID } = useParams();
  const { data: supplier } = useGetSupplier(Number(supplierID));
  const { mutate: savePricelist, isPending: isSaving } = useSavePricelist();
  const {
    data: pricelist,
    isLoading: isLoadingPricelist,
    isSuccess: isSuccessPricelist,
  } = useGetPricelist(Number(pricelistID));
  const { page, setPage, rowsPerPage, updateRowsPerPage } =
    useTablePagination();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    result: searchResult,
    updateProduct,
    addProduct,
    deleteProduct,
    setSearchQuery,
    searchQuery,
    setProductsStore,
    allProducts,
  } = useSearchableProducts(pricelist?.products || []);

  const handlePublicPriceUpdate = (productID: number, publicPrice: number) => {
    const result = searchResult.find(
      (product) => product.productID === productID,
    );

    if (result) {
      updateProduct({ ...result, publicPrice: publicPrice ?? 0 });
    }
  };

  const handlePrivatePriceUpdate = (
    productID: number,
    privatePrice: number,
  ) => {
    const result = searchResult.find(
      (product) => product.productID === productID,
    );

    if (result) {
      updateProduct({ ...result, privatePrice: privatePrice ?? 0 });
    }
  };

  const handleAddProduct = (
    newProduct: Product,
    publicPrice: number,
    privatePrice: number,
  ) => {
    addProduct({
      ...newProduct,
      privatePrice: privatePrice || 0,
      publicPrice: publicPrice || 0,
    });
    setIsDialogOpen(false);
  };

  const handleSavePricelist = () => {
    if (!supplier) {
      console.error('Pricelist save: missing supplier');
      return;
    }

    savePricelist(
      {
        pricelistID: supplier.pricelistID,
        products: allProducts,
        supplierID: supplier.supplierID || 0,
        supplierName: supplier.name,
      },
      {
        onSuccess: () => {
          navigate(-1);
        },
      },
    );
  };

  const handleSearchProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  useEffect(() => {
    if (isSuccessPricelist && pricelist) {
      setProductsStore(pricelist.products || []);
    }
  }, [pricelist, isSuccessPricelist, setProductsStore]);

  return (
    <Page>
      <>
        <Header>
          <div className={styles.pageTitleContainer}>
            <h3 className={styles.pageTitle}>
              <IconButton
                size="small"
                aria-label={supplier?.name}
                className={styles.backButton}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosNewIcon fontSize="inherit" />
              </IconButton>
              <div className={styles.supplierName}>{supplier?.name}</div>
            </h3>
          </div>
          <div className={styles.headerActions}>
            <TextField
              className={styles.searchTextField}
              placeholder={t('commons.searchProduct')}
              size="small"
              value={searchQuery}
              onChange={handleSearchProduct}
            />
            <LoadingButton
              loading={isSaving}
              startIcon={<DoneIcon />}
              onClick={handleSavePricelist}
            >
              {t('supplier.save')}
            </LoadingButton>
          </div>
        </Header>
        <Body>
          {isLoadingPricelist ? (
            <TableLoader />
          ) : (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table aria-label={t('supplier.pricelistTable')}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell align="center">#</TableHeadCell>
                    <TableHeadCell>{t('supplier.product')}</TableHeadCell>
                    <TableHeadCell>{t('supplier.brand')}</TableHeadCell>
                    <TableHeadCell align="center">
                      {t('supplier.category')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('supplier.pricelistPrice')}
                    </TableHeadCell>
                    <TableHeadCell align="center">
                      {t('supplier.canvas')}
                    </TableHeadCell>
                    <TableHeadCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? searchResult.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : searchResult
                  ).map((product, idx) => (
                    <TableRow key={product.productID}>
                      <TableCell style={{ width: 50 }} align="center">
                        {idx + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ width: '40%' }}>
                        {product.fullName}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        {product.brand}
                      </TableCell>
                      <TableCell style={{ width: '15%' }} align="center">
                        {product.categoryName}
                      </TableCell>
                      <TableCell
                        style={{ width: '15%', padding: '0 16px' }}
                        align="center"
                      >
                        <CurrencyField
                          size="small"
                          fullWidth
                          value={product.publicPrice}
                          onChange={(value) =>
                            handlePublicPriceUpdate(product.productID, value)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ width: '15%', padding: '0 16px' }}
                        align="center"
                      >
                        <CurrencyField
                          size="small"
                          fullWidth
                          value={product.privatePrice}
                          onChange={(value) =>
                            handlePrivatePriceUpdate(product.productID, value)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ width: 50, padding: '0 10px 0 0' }}
                        align="center"
                      >
                        <IconButton
                          size="small"
                          aria-label={t('commons.stock')}
                          color="error"
                          className={styles.deleteItemIcon}
                          onClick={() => deleteProduct(product.productID)}
                        >
                          <DeleteOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={7}
                      count={searchResult.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': t('commons.rowPerPage'),
                        },
                        native: true,
                      }}
                      labelRowsPerPage={t('commons.rowPerPage')}
                      onPageChange={(_event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) =>
                        updateRowsPerPage(event.target.value)
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Body>

        <AddItemButton
          onClick={() => {
            setIsDialogOpen(true);
          }}
        />

        <AddPricelistProduct
          isOpen={isDialogOpen}
          onSubmit={handleAddProduct}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      </>
    </Page>
  );
};

export default SupplierPage;
