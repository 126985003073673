import { useQuery } from '@tanstack/react-query';

import { getUnloadCategoriesTrend } from '@/lib/api/analytics';
import { CategoryAnalyticsTrend } from '@/models/Analytics';

import { getUnloadCategoriesTrendQueryKey } from './queryKeys';

const useGetUnloadCategoriesTrend = (from: string, to: string) => {
  return useQuery<CategoryAnalyticsTrend[], Error>({
    queryKey: getUnloadCategoriesTrendQueryKey(from, to),
    queryFn: () => getUnloadCategoriesTrend(from, to),
  });
};

export default useGetUnloadCategoriesTrend;
