import MUIFab, { FabProps } from '@mui/material/Fab';
import cx from 'classnames';

import styles from './Fab.module.scss';

const Fab = ({ className, ...props }: FabProps) => {
  return <MUIFab className={cx(styles.container, className)} {...props} />;
};

export default Fab;
