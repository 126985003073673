import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { redirect } from 'react-router-dom';

import { useAuth } from '@/context/auth';
import useLogout from '@/queries/auth/useLogout';
import Button from '@/uikit/components/Button/Button';
import IconButton from '@/uikit/components/IconButton';

import styles from './Account.module.scss';

const Account = ({ className }: { className?: string }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { mutate: logout } = useLogout();

  if (!user) return null;

  const handleLogout = async () => {
    await logout();

    redirect('/login');
  };

  const handleChangeLanguage = (language: string) => {
    console.log(i18n.language);

    i18n.changeLanguage(language);
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.language}>
        <IconButton
          className={cx({ [styles.isNotSelected]: i18n.language !== 'en' })}
          onClick={() => handleChangeLanguage('en')}
        >
          🇬🇧
        </IconButton>
        <IconButton
          className={cx({ [styles.isNotSelected]: i18n.language !== 'it' })}
          onClick={() => handleChangeLanguage('it')}
        >
          🇮🇹
        </IconButton>
      </div>
      <Button
        size="M"
        variant="outlined"
        startIcon={<LogoutOutlinedIcon />}
        fullWidth
        color="secondary"
        onClick={handleLogout}
      >
        {t('commons.logout')}
      </Button>
    </div>
  );
};

export default Account;
