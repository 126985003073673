import { UnitOfMeasure } from '@/models/Product';

export const unitOfMeasureList: readonly UnitOfMeasure[] = [
  UnitOfMeasure.CL,
  UnitOfMeasure.ML,
  UnitOfMeasure.LT,
  UnitOfMeasure.UNIT,
  UnitOfMeasure.GR,
  UnitOfMeasure.KG,
];
