import { useState } from 'react';

const useTablePagination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const updateRowsPerPage = (rowsNumber: string) => {
    setRowsPerPage(parseInt(rowsNumber, 10));
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    setPage,
    updateRowsPerPage,
  };
};

export default useTablePagination;
