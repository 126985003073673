import { InputAdornment } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import TextField, { TextFieldProps } from '@/uikit/components/TextField';

type CurrencyFieldProps = Omit<
  TextFieldProps,
  'value' | 'defaultValue' | 'type' | 'onChange'
> &
  Pick<NumericFormatProps, 'value' | 'defaultValue'> & {
    onChange?: (value: number) => void;
  };

const CurrencyField = ({ onChange, ...rest }: CurrencyFieldProps) => {
  return (
    <NumericFormat
      allowNegative={false}
      allowedDecimalSeparators={[',', '.']}
      decimalSeparator="."
      decimalScale={4}
      thousandSeparator=" "
      customInput={TextField}
      onValueChange={(values) => {
        onChange?.(values.floatValue ?? 0);
      }}
      onFocus={(event) => {
        event.target.select();
      }}
      placeholder="0"
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
      {...rest}
    />
  );
};

export default CurrencyField;
