import Page from '@/components/Page';
import Body from '@/components/Page/Body';

import styles from './Order.module.scss';

const OrderList = () => {
  return (
    <Page>
      <Body>
        <div className={styles.container}>
          <h2>🚧 Coming soon 🚧</h2>
        </div>
      </Body>
    </Page>
  );
};

export default OrderList;
