import { Auth, CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';

import { AuthTokens } from '@/models/Auth';
import { User } from '@/models/User';

type Credentials = {
  username: string;
  password: string;
};

type SignInReturn =
  | { user?: User; tokens?: AuthTokens; cognitoUser: CognitoUser }
  | undefined;

export const signIn = async ({
  username,
  password,
}: Credentials): Promise<SignInReturn> => {
  const cognitoUser: CognitoUser = await Auth.signIn({
    username,
    password,
  });

  const userSession = cognitoUser.getSignInUserSession();

  if (userSession) {
    const { data: user } = await axios.get<User>('/user');

    return {
      user,
      cognitoUser,
      tokens: {
        accessToken: userSession.getIdToken().getJwtToken(),
        refreshToken: userSession.getRefreshToken().getToken(),
      },
    };
  }

  return {
    cognitoUser,
  };
};

export const setNewPassword = async ({
  password,
  cognitoUser,
}: {
  password: string;
  cognitoUser: CognitoUser;
}): Promise<SignInReturn> => {
  const newUser: CognitoUser = await Auth.completeNewPassword(
    cognitoUser,
    password,
  );

  const userSession = newUser.getSignInUserSession();

  const { data: user } = await axios.get<User>('/user');

  return {
    user,
    cognitoUser,
    tokens: {
      accessToken: userSession?.getIdToken().getJwtToken(),
      refreshToken: userSession?.getRefreshToken().getToken(),
    },
  };
};

export const getCurrentUser = async (): Promise<User | null> => {
  await Auth.currentAuthenticatedUser();

  const { data } = await axios.get<User>('/user');

  return data;
};

export const resetCurrentCognitoUser = (): Promise<void> => {
  return Auth.signOut();
};
