import MUITableRow, { TableRowProps } from '@mui/material/TableRow';
import cx from 'classnames';

import styles from './TableRow.module.scss';

const TableRow = ({
  className,
  clickable = false,
  ...props
}: TableRowProps & { clickable?: boolean }) => {
  return (
    <MUITableRow
      className={cx(
        styles.container,
        { [styles.clickable]: clickable },
        className,
      )}
      {...props}
    />
  );
};

export default TableRow;
