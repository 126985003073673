import { useQuery } from '@tanstack/react-query';

import { getSupplierProduct } from '@/lib/api/supplier';
import { PricelistProduct } from '@/models/Pricelist';

import { getSupplierProductQueryKey } from './queryKeys';

const useGetSupplierProduct = (supplierId: number, productId: number) => {
  return useQuery<PricelistProduct, Error>({
    queryKey: getSupplierProductQueryKey(supplierId, productId),
    queryFn: () => getSupplierProduct(supplierId, productId),
    enabled: Boolean(productId),
  });
};

export default useGetSupplierProduct;
