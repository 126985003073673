import { TableHead as MUITableHead } from '@mui/material';

import styles from './TableHead.module.scss';

const TableHead = ({ children }: { children: React.ReactNode }) => {
  return (
    <MUITableHead classes={{ root: styles.tableHead }}>{children}</MUITableHead>
  );
};

export default TableHead;
