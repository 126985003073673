import axios from 'axios';

import { AnalyticsTrend } from '@/models/Analytics';
import { NewProduct, Product } from '@/models/Product';

import { formatSearchName } from '../utils';

export const findProductByName = async (query: string) => {
  if (!query) {
    return [];
  }

  const response = await axios.get<Product[]>(
    `/products/search/${encodeURI(formatSearchName(query))}`,
  );

  return response.data;
};

export const createCustomProduct = async (data: NewProduct) => {
  const response = await axios.post<NewProduct, { data: Product }>(
    '/products/custom',
    {
      data,
    },
  );

  return response.data;
};

export const getProductEntries = async (
  productID: number,
  from: string,
  to: string,
) => {
  const response = await axios.get<AnalyticsTrend>(
    `/products/entry/${productID}`,
    {
      params: { from, to },
    },
  );

  return response.data;
};

export const getProductUsage = async (
  productID: number,
  from: string,
  to: string,
) => {
  const response = await axios.get<AnalyticsTrend>(
    `/products/usage/${productID}`,
    {
      params: { from, to },
    },
  );

  return response.data;
};
