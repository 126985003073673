import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuantityField from '@/components/QuantityField/QuantityField';
import SearchProductField from '@/components/SearchProductField';
import { Product } from '@/models/Product';
import Button from '@/uikit/components/Button';
import Dialog from '@/uikit/components/Dialog';

import styles from './AddProductQuantity.module.scss';

type AddProductQuantityProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (product: Product, quantity: number) => void;
};

const AddProductQuantity = ({
  title,
  isOpen,
  onClose,
  onSubmit,
}: AddProductQuantityProps) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<Product | null>(null);
  const [quantity, setQuantity] = useState(0);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const handleAddProduct = () => {
    if (product) {
      onSubmit(product, quantity);
      setProduct(null);
      setQuantity(0);
      searchFieldRef.current?.focus();
    }
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <SearchProductField
            ref={searchFieldRef}
            className={styles.name}
            product={product}
            onChange={(item: Product | null) => {
              setProduct(item);
            }}
          />
          <QuantityField
            fullWidth
            className={styles.quantity}
            label={t('addProductQuantity.quantity')}
            value={quantity}
            onChange={setQuantity}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('addProductQuantity.cancel')}
        </Button>
        <Button color="secondary" onClick={handleAddProduct}>
          {t('addProductQuantity.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductQuantity;
