import { BarChart } from '@mui/icons-material';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { endOfToday, sub } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchProductField from '@/components/SearchProductField/SearchProductField';
import TrendBarChart from '@/components/widgets/TrendBarChart';
import TrendPieChart from '@/components/widgets/TrendPieChart';
import { AnalyticsTrend } from '@/models/Analytics';
import { Category } from '@/models/Category';
import { Product } from '@/models/Product';
import useFetchAllCategories from '@/queries/category/useFetchAllCategories';
import Button from '@/uikit/components/Button';
import DatePicker from '@/uikit/components/DatePicker/DatePicker';
import Dialog from '@/uikit/components/Dialog';
import Dropdown from '@/uikit/components/Dropdown';
import LoadingButton from '@/uikit/components/LoadingButton';
import TextField from '@/uikit/components/TextField';

import styles from './Trends.module.scss';

export type SearchType = 'product' | 'category';

export type OnQueryParams = {
  type: SearchType;
  id: number;
  fromDate: Date;
  toDate: Date;
};

type TrendsProps = {
  title?: string;
  data: AnalyticsTrend | null;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onQuery: (params: OnQueryParams) => void;
};

const Trends = ({
  title,
  isOpen,
  isLoading = false,
  onClose,
  onQuery,
  data = null,
}: TrendsProps) => {
  const { t } = useTranslation();
  const { data: categories = [], isLoading: isCatagoriesLoading } =
    useFetchAllCategories();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [searchType, setSearchType] = useState<SearchType>('product');
  const [from, setFrom] = useState<Date | null>(
    sub(endOfToday(), { days: 30 }),
  );
  const [to, setTo] = useState<Date | null>(endOfToday());
  const autoCompleteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const handleCategorySelected = (value: string | Category | null) => {
    if (typeof value === 'string' || value === null) return;

    setSelectedCategory(value);
  };

  const handleQuery = () => {
    onQuery({
      type: searchType,
      id:
        searchType === 'product'
          ? selectedProduct?.productID || 0
          : selectedCategory?.categoryID || 0,
      fromDate: from || sub(endOfToday(), { days: 30 }),
      toDate: to || endOfToday(),
    });
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      className={styles.container}
    >
      <DialogTitle className={styles.title}>
        <BarChart className={styles.titleIcon} />
        {title || t('trendsDialog.trends')}
        <DatePicker
          label={t('commons.from')}
          format={t('dates.dateFormat')}
          value={from}
          onChange={setFrom}
        />
        <DatePicker
          label={t('commons.to')}
          format={t('dates.dateFormat')}
          value={to}
          onChange={setTo}
        />
      </DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <section
            aria-label={t('trendsDialog.queryParams')}
            className={styles.queryParams}
          >
            <Dropdown
              id={'select-query-type'}
              label={t('trendsDialog.searchBy')}
              values={[
                { label: t('trendsDialog.product'), value: 'product' },
                { label: t('trendsDialog.category'), value: 'category' },
              ]}
              defaultValue={'product'}
              onChange={(value) => setSearchType(value as SearchType)}
            />
            {searchType === 'product' && (
              <SearchProductField
                disableCreate
                product={selectedProduct}
                onChange={(item: Product | null) => {
                  setSelectedProduct(item);
                }}
              />
            )}
            {searchType === 'category' && (
              <Autocomplete
                disableClearable
                autoHighlight
                className={styles.name}
                options={categories}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                noOptionsText={t('trendsDialog.noResult')}
                loading={isCatagoriesLoading}
                loadingText={t('trendsDialog.loading')}
                value={selectedCategory}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    autoFocus
                    inputRef={autoCompleteRef}
                    label={t('trendsDialog.categoryLabel')}
                    placeholder={t('trendsDialog.categoryPlaceholder')}
                    variant="outlined"
                    margin="none"
                  />
                )}
                onChange={(_event, value) => handleCategorySelected(value)}
              />
            )}
          </section>
          <section className={styles.graphContainer}>
            <TrendBarChart data={data} />
            <TrendPieChart data={data} />
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('trendsDialog.cancel')}
        </Button>
        <LoadingButton
          color="secondary"
          onClick={handleQuery}
          loading={isLoading}
        >
          {t('trendsDialog.search')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Trends;
