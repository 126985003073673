import {
  TableContainer as MUITableContainer,
  PaperTypeMap,
  TableContainerProps,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import styles from './TableContainer.module.scss';

const TableContainer = ({
  children,
}: TableContainerProps & {
  component: OverridableComponent<PaperTypeMap<object, 'div'>>;
}) => {
  return (
    <MUITableContainer className={styles.tableContainer}>
      {children}
    </MUITableContainer>
  );
};

export default TableContainer;
