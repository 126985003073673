import { TableCell as MUITableCell, TableCellProps } from '@mui/material';

import styles from './TableHeadCell.module.scss';

const TableHeadCell = ({ children, ...rest }: TableCellProps) => {
  return (
    <MUITableCell className={styles.tableCell} {...rest}>
      {children}
    </MUITableCell>
  );
};

export default TableHeadCell;
