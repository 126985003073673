import { useQuery } from '@tanstack/react-query';

import { fetchAllSuppliers } from '@/lib/api/supplier';
import { Supplier } from '@/models/Supplier';

import { fetchAllSuppliersQueryKey } from './queryKeys';

const useFetchAllSuppliers = () => {
  return useQuery<Supplier[], Error>({
    queryKey: fetchAllSuppliersQueryKey(),
    queryFn: fetchAllSuppliers,
  });
};

export default useFetchAllSuppliers;
