import axios from 'axios';

import { Unload } from '@/models/Unload';

export const fetchAllUnloads = async () => {
  const response = await axios.get<Unload[]>('/unloads');

  return response.data;
};

export const getUnloadByID = async (unloadID: number) => {
  const response = await axios.get<Unload>(`/unloads/${unloadID}`);

  return response.data;
};

export const saveUnload = async (unload: Unload) => {
  const response = await axios.post<Unload>(
    `/unloads/${unload.unloadID || 'new'}`,
    {
      data: { unload },
    },
  );

  return response.data;
};

export const deleteUnload = async (unloadID: number) => {
  await axios.delete(`/unloads/${unloadID}`);

  return unloadID;
};
